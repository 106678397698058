import type { ReactElement } from 'react'
import type { Store } from 'redux'
import { fromJS } from 'immutable'
import { browserHistory as history } from 'react-router'
import { useState } from 'react'
import { useStore } from 'react-redux'

import type { ProductDataType } from '../Workspace/plugins/category/CategoryPlugin'
import {
  buildFilterArray,
  resetAllCategoryFilters,
  selectCategorySearchFacetValue,
  setCategorySearchFacetRange,
  setProductsForCategoryAsync,
} from '../../../store/actions'
import { generateCategoryUrl, withQueryAndScope } from '../../../urlGenerators'
import { getFilters } from './FacetedSearchToolbarTop'
import { pagingDefaults, sortingDefaults } from '../../../utils/pageAndSort'
import SearchFilterButton from './SearchFilterButton'
import SearchFilterOverlay from './SearchFilterOverlay'
import SearchFilters from './SearchFilters'
import ToolbarTop from '../ToolbarTop'
import buildURL from '../../../../utils/buildURL'

export function changeCategorySearchQuery(store: Store<State>, slug: string, categoryId: string): void {
  const state = store.getState()
  const location = state.get('location')
  const locationQuery = location.get('query').toJS()
  const productData = store.getState().getIn(['categoryProductData', categoryId])
  const facets: Core.Facets | undefined = productData?.facets
  const imageSize = store.getState().getIn(['categories', categoryId, 'settings', 'imageSize'], 'M')
  const pageSize = imageSize === 'S' ? 15 : 12

  const searchFilters = buildFilterArray(locationQuery)
  const searchParams = new URLSearchParams()
  const searchParamsObj = getFilters(facets)

  Object.entries(searchParamsObj).forEach(([key, param]) => {
    if (Array.isArray(param)) {
      param.forEach((value) => searchParams.append(key, value))
    } else {
      searchParams.append(key, param)
    }
  })

  if (locationQuery.sort) searchParams.append('sort', locationQuery.sort)

  const categorySlug = fromJS({
    slug,
  })

  store.dispatch<any>(
    setProductsForCategoryAsync(
      categoryId,
      {
        page: pagingDefaults.page,
        resultsPerPage: pageSize,
        sort: locationQuery.sort ?? sortingDefaults.sort,
      },
      searchFilters,
    ),
  )

  // replace URL in browser history and load it
  history.replace(buildURL(withQueryAndScope(generateCategoryUrl(categorySlug), location), searchParams))
}

function scrollToMarker(marker: string) {
  const target = document.querySelector(`[data-marker="${marker}"]`)

  if (target) target.scrollIntoView({ behavior: 'smooth' })
}

type Props = {
  category: Core.Page & {
    categoryId: string
    objectId: number
    facetedSearchCategoryStartDepth: number
    facetedSearchShowFacetsOnCategory: boolean
  }
  categoryDepth: number
  productData: ProductDataType
  isBusy: boolean
  isEditor?: boolean
  onScrollIntoView: VoidFunction
} & TranslateProps

export default function CategorySearch({
  category,
  categoryDepth,
  productData,
  isBusy,
  isEditor = false,
  t,
  onScrollIntoView,
}: Props): ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const store = useStore<State>()

  const handleSelectionFilterChange = (facetId: string, value: Core.FacetSelectionValue) => {
    store.dispatch(selectCategorySearchFacetValue(facetId, value.value, category.categoryId, !value.selected))
    changeCategorySearchQuery(store, category.slug, category.categoryId)
    scrollToMarker('product-section')
  }

  const handleRangeFilterChange = (facetId: string, range: { min: number; max: number }) => {
    store.dispatch(setCategorySearchFacetRange(facetId, category.categoryId, range))
    changeCategorySearchQuery(store, category.slug, category.categoryId)
    scrollToMarker('product-section')
  }

  const handleResetAllCategoryFilters = () => {
    store.dispatch(resetAllCategoryFilters(category.categoryId))
    changeCategorySearchQuery(store, category.slug, category.categoryId)
  }

  const hasFacets = Object.keys(productData.facets || {}).length !== 0

  const showCategorySearch = category.facetedSearchShowFacetsOnCategory
    ? hasFacets || isEditor
      ? categoryDepth > category.facetedSearchCategoryStartDepth
      : false
    : false

  return showCategorySearch ? (
    <ToolbarTop className="faceted-search-toolbar-top" onScrollIntoView={onScrollIntoView}>
      <SearchFilters
        facets={productData.facets}
        onSelectionFilterChange={handleSelectionFilterChange}
        onRangeFilterChange={handleRangeFilterChange}
      />
      <div className="search-configuration">
        <SearchFilterButton onClick={() => setIsOpen(true)}>
          {t('components.facetedSearch.filterButton.label')}
        </SearchFilterButton>
      </div>
      <SearchFilterOverlay
        facets={productData.facets}
        totalNumberOfProducts={productData.totalNumberOfProducts || 0}
        isBusy={isBusy}
        isOpen={isOpen}
        onSelectionFacetChange={(facet, value) => {
          handleSelectionFilterChange(facet.id, value)
        }}
        onRangeFacetChange={(facet, range) => {
          handleRangeFilterChange(facet.id, range)
        }}
        onResetAllFilters={handleResetAllCategoryFilters}
        onRequestClose={() => setIsOpen(false)}
        t={t}
      />
    </ToolbarTop>
  ) : null
}
