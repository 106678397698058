import type { AxiosInstance } from 'axios'
import type { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import axios from 'axios'

export const ApiContext = createContext<AxiosInstance>(axios.create({ baseURL: '/', maxRedirects: 0 }))

type Props = {
  api: AxiosInstance
}
export function ApiProvider({ children, api }: PropsWithChildren<Props>): React.ReactElement {
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}

export function useApi(): AxiosInstance {
  const api = useContext(ApiContext)
  return api
}
