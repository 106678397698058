import type { Location } from 'history'
import type { ReactElement } from 'react'
import type { Store } from 'redux'
import { browserHistory as history } from 'react-router'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useState } from 'react'

import { generateSearchUrl, withQueryAndScope } from '../../../urlGenerators'
import { getPlain } from '../../../store/utils'
import { rangeFacetDelimiter } from '../../../utils/pageAndSort'
import { resetAllFilters, selectFacetedSearchFacetValue, setFacetedSearchFacetRange } from '../../../store/actions'
import SearchFilterButton from './SearchFilterButton'
import SearchFilterOverlay from './SearchFilterOverlay'
import SearchFilters from './SearchFilters'
import SearchSortingForm from '../../themeComponents/epages.base/SearchSortingForm'
import ToolbarTop from '../ToolbarTop'
import buildURL from '../../../../utils/buildURL'

export const getFilters = (facets?: Core.Facets): { [key: string]: string[] } => {
  const filters = {}

  if (facets) {
    Object.values(facets).forEach((facet) => {
      switch (facet.type) {
        case 'selection':
          Object.values(facet.values).forEach((value) => {
            if (value.selected) {
              if (!Object.keys(filters).includes(facet.id)) {
                filters[facet.id] = [value.value]
              } else {
                filters[facet.id].push(value.value)
              }
            }
          })
          break
        case 'range':
          if (facet.values?.selected) {
            if (!Object.keys(filters).includes(facet.id)) {
              filters[facet.id] = [facet.values.selection.min + rangeFacetDelimiter + facet.values.selection.max]
            } else {
              filters[facet.id].push(facet.values.selection.min + rangeFacetDelimiter + facet.values.selection.max)
            }
          }
          break
      }
    })
  }
  return filters
}

export type Query = {
  q: string
  page: string
  sort: string
  // Any facetted search filter
  [key: string]: string
}

export type SearchOptions = {
  q?: string
  sort?: string
  page?: number
}

export const changeSearchQuery = (store: Store<State>, options?: SearchOptions): void => {
  const state = store.getState()
  const location = state.get('location')
  const defaultSorting = 'relevance'

  const currentOptions: Required<SearchOptions> = {
    q: location.toJS().query.q || '',
    page: 1,
    sort: location.toJS().query.sort || defaultSorting,
    ...options,
  }

  const facets = getPlain<Core.Facets | undefined>(state.getIn(['searchData', 'facets'], undefined))

  const searchParams = new URLSearchParams()
  searchParams.append('q', currentOptions.q)
  searchParams.append('sort', currentOptions.sort)
  searchParams.append('page', currentOptions.page.toString())
  const searchParamsObj = getFilters(facets)
  Object.entries(searchParamsObj).forEach(([key, value]) => {
    value.forEach((element) => searchParams.append(key, element))
  })

  // replace URL in browser history and load it
  history.replace(buildURL(withQueryAndScope(generateSearchUrl(), location), searchParams))
}

type Props = {
  searchData: any
  isBusy: boolean
  onScrollIntoView: VoidFunction
} & TranslateProps

function FacetedSearchToolbarTop({ isBusy, onScrollIntoView, searchData, t }: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  const store = useStore<State>()
  const location = useSelector<State, Location>((state) => state.get('location').toJS())
  const queryString = location.query.q as string
  const dispatch = useDispatch()

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleQueryFilterChange = () => {
    changeSearchQuery(store, { q: '*' })
  }

  const handleSelectionFilterChange = (facetId: string, value: Core.FacetSelectionValue) => {
    dispatch(selectFacetedSearchFacetValue(facetId, value.value, !value.selected))
    changeSearchQuery(store)
    scrollToTop()
  }

  const handleRangeFilterChange = (facetId: string, range: { min: number; max: number }) => {
    dispatch(setFacetedSearchFacetRange(facetId, range))
    changeSearchQuery(store)
    scrollToTop()
  }

  const handleResetAllCategoryFilters = () => {
    dispatch(resetAllFilters())
    changeSearchQuery(store)
    scrollToTop()
  }

  return (
    <ToolbarTop className="faceted-search-toolbar-top" onScrollIntoView={onScrollIntoView}>
      <SearchFilters
        facets={searchData.facets}
        queryString={queryString}
        onQueryFilterChange={handleQueryFilterChange}
        onSelectionFilterChange={handleSelectionFilterChange}
        onRangeFilterChange={handleRangeFilterChange}
      />
      <div className="search-configuration">
        <SearchSortingForm searchData={searchData} t={t} />
        <SearchFilterButton onClick={() => setIsOpen(true)}>
          {t('components.facetedSearch.filterButton.label')}
        </SearchFilterButton>
        <SearchFilterOverlay
          facets={searchData.facets}
          totalNumberOfProducts={searchData.totalNumberOfProducts || 0}
          isBusy={isBusy}
          isOpen={isOpen}
          onSelectionFacetChange={(facet, value) => {
            handleSelectionFilterChange(facet.id, value)
          }}
          onRangeFacetChange={(facet, range) => {
            handleRangeFilterChange(facet.id, range)
          }}
          onResetAllFilters={handleResetAllCategoryFilters}
          onRequestClose={() => setIsOpen(false)}
          t={t}
        />
      </div>
    </ToolbarTop>
  )
}

export default FacetedSearchToolbarTop
