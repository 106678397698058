import type { ReactElement } from 'react'

import Plugin from '../../templateComponents/Plugin'

type Props = {
  page: Core.LegalPage
}

export default function Legal({ page }: Props): ReactElement {
  return (
    <div className="template-legal">
      <h1>{page.title}</h1>
      <Plugin type="epages.text" _id="legal-content" />
    </div>
  )
}
