import React, { useRef, useState } from 'react'
import loadable from '@loadable/component'

import Button from '../../formComponents/Button'
import ButtonRow from '../../formComponents/ButtonRow'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ './SettingsLayer'))

export type BlockAnimation = 'none' | 'fade-in' | 'fade-in-left' | 'fade-in-right' | 'fade-in-bottom'

type Props = {
  animation: BlockAnimation
  initialAnimation: BlockAnimation
  handleAnimationChange: (animation: BlockAnimation) => void
  handleAnimationReplay: (animation: BlockAnimation) => void
  saveAnimationChange: () => void
  setAnimation: (animation: BlockAnimation) => void
} & TranslateProps

export default function BlockAnimationSettings({
  t,
  animation,
  initialAnimation,
  handleAnimationChange,
  handleAnimationReplay,
  saveAnimationChange,
  setAnimation,
}: Props): React.ReactElement {
  const [showAnimationSettings, setShowAnimationSettings] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)
  const scopedT = (key: string) => t(`components.elementContextBarComponent.animationButton.settingsLayer.${key}`)

  const saveAnimation = () => {
    setShowAnimationSettings(false)
    saveAnimationChange()
  }

  const cancelHandler = () => {
    setShowAnimationSettings(false)
    setAnimation(initialAnimation)
  }

  const toggleAnimationSettings = () => {
    if (showAnimationSettings) {
      cancelHandler()
    } else {
      setShowAnimationSettings(true)
      // prevent wrong initial settings layer positioning
      setTimeout(() => {
        handleAnimationReplay(animation)
      }, 150)
    }
  }

  const title = scopedT('title')
  const explanation = scopedT('tooltip')
  const body = (
    <>
      <label className="ep-form-row-label" htmlFor="animation">
        {scopedT('animationDropdown.label')}
      </label>
      <select
        className="ep-custom-select"
        name="animation"
        id="animation"
        onChange={(event) => handleAnimationChange(event.target.value as BlockAnimation)}
        defaultValue={animation}
      >
        <option value="none">{scopedT('animationDropdown.options.noAnimation')}</option>
        <option value="fade-in">{scopedT('animationDropdown.options.fadeIn')}</option>
        <option value="fade-in-left">{scopedT('animationDropdown.options.fadeInLeft')}</option>
        <option value="fade-in-right">{scopedT('animationDropdown.options.fadeInRight')}</option>
        <option value="fade-in-bottom">{scopedT('animationDropdown.options.fadeInBottom')}</option>
      </select>
      <button
        className="ep-settingslayer-block-animation-settings-replay-button"
        onClick={() => handleAnimationReplay(animation)}
      >
        {scopedT('replayButton.label')}
      </button>
    </>
  )
  const footer = (
    <ButtonRow>
      <Button type="button" variant="cancel" onClick={cancelHandler}>
        {scopedT('cancelButton.label')}
      </Button>
      <Button type="button" onClick={saveAnimation}>
        {scopedT('saveButton.label')}
      </Button>
    </ButtonRow>
  )

  return (
    <>
      <div
        ref={buttonRef}
        className="dali-block-actionbar-button dali-block-actionbar-button-animation"
        title={t('components.elementContextBarComponent.animationButton.label')}
        onClick={() => toggleAnimationSettings()}
      />
      {showAnimationSettings && (
        <SettingsLayer
          className="ep-settingslayer-block-animation-settings"
          referenceElement={buttonRef.current}
          placement="right"
          onEscapeKeyDown={cancelHandler}
        >
          {({ renderLayout }) =>
            renderLayout({
              title,
              explanation,
              body,
              footer,
            })
          }
        </SettingsLayer>
      )}
    </>
  )
}
