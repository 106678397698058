import { List } from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'

import { PageOrCategoryContext } from '../../../views/Category'
import { updateCategoryContent, updateLegalPageContent, updatePageContent } from '../../../store/actions'
import Dali from './Dali'
import daliConfig from './daliConfig'
import withReduxContext from '../../../utils/withReduxContext'

const WorkspaceRaw = (props) => {
  const { store } = props
  const pageOrCategory = React.useContext(PageOrCategoryContext)

  const shopSlug = store.getState().getIn(['shop', 'slug'])
  const editorMode = store.getState().getIn(['view', 'editorMode'])
  const blocks = pageOrCategory.getIn(['content', 'blocks']) || new List()

  const handleBlocksChange = (newBlocks) => {
    const oldContent = pageOrCategory.get('content')
    const newContent = oldContent.set('blocks', newBlocks)

    switch (pageOrCategory.get('type')) {
      case 'blank':
        return store.dispatch(updatePageContent(pageOrCategory.get('slug'), newContent))
      case 'legal':
        return store.dispatch(updateLegalPageContent(pageOrCategory.get('slug'), newContent))
      case 'category':
        return store.dispatch(updateCategoryContent(pageOrCategory.get('guid'), newContent))
    }
  }
  return (
    <Dali
      config={daliConfig(editorMode, store.api, shopSlug)}
      editorView={editorMode}
      blocks={blocks}
      pageSlug={pageOrCategory.get('slug')}
      onBlocksChange={handleBlocksChange}
    />
  )
}

WorkspaceRaw.propTypes = {
  store: PropTypes.object.isRequired,
}

export default withReduxContext(WorkspaceRaw)
