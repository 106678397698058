import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import React from 'react'
import cc from 'classcat'

import * as globalDragStatus from './utils/global-drag-status'
import { DropTarget } from './utils/DragAndDrop'
import Block from './Block'
import DropZone from './utils/DropZone'
import wrapAndJoin from './utils/wrap-and-join'

export default class Dali extends React.PureComponent {
  static propTypes = {
    config: PropTypes.object.isRequired,
    editorView: PropTypes.bool.isRequired,
    blocks: ImmutablePropTypes.list.isRequired,
    pageSlug: PropTypes.string.isRequired,
    onBlocksChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    editorView: false,
  }

  state = {
    isDragActive: false,
  }

  componentDidMount() {
    globalDragStatus.listen(this.handleGlobalDragStatusChange)
  }

  componentWillUnmount() {
    globalDragStatus.unlisten(this.handleGlobalDragStatusChange)
  }

  render() {
    const { editorView } = this.props
    const { isDragActive } = this.state

    const className = cc([
      {
        'dali-grid': true,
        'dali-grid-editview': editorView,
        'dali-grid-dragactive': isDragActive,
      },
    ])

    return (
      <div className={className}>
        {editorView ? wrapAndJoin(this.renderBlocks(), this.renderDropZone) : this.renderBlocks()}
      </div>
    )
  }

  renderBlocks = () => {
    const { config, editorView, blocks, pageSlug } = this.props

    return blocks.map((block, index) => (
      <Block
        // ids are shared across page clones :(
        key={`${pageSlug}${block.get('_id')}`}
        blockPosition={index}
        block={block}
        isDragActive={this.state.isDragActive}
        config={config}
        editorView={editorView}
        onChange={this.handleBlockChange(index)}
        onDelete={this.handleBlockDelete(index)}
      />
    ))
  }

  renderDropZone = (index) => {
    const { blocks } = this.props

    return (
      <DropTarget
        key={index}
        dropTargetPosition={index}
        orientation="horizontal"
        isOnEmptyPage={blocks.count() === 0}
        component={DropZone}
        type="dali-add"
        onDrop={this.handleDrop(index)}
      />
    )
  }

  handleDrop = (index) => {
    const { blocks, onBlocksChange } = this.props

    return function (block) {
      // might be an insert or a move. in case of a move, then the block in its old position has to be removed
      const removeOld = (list) => list.filter((b) => b.get('_id') !== block.get('_id'))

      onBlocksChange(
        removeOld(blocks.slice(0, index))
          .push(block)
          .concat(removeOld(blocks.slice(index))),
      )
    }
  }

  handleBlockChange = (index) => {
    const { blocks, onBlocksChange } = this.props

    return function (block) {
      onBlocksChange(blocks.set(index, block))
    }
  }

  handleBlockDelete = (index) => {
    const { blocks, onBlocksChange } = this.props

    return function () {
      onBlocksChange(blocks.slice(0, index).concat(blocks.slice(index + 1)))
    }
  }

  handleGlobalDragStatusChange = (isDragActive) => {
    const active = isDragActive && window.dali.globalDragType === 'dali-add'
    this.setState({ isDragActive: active })
  }
}
