import type { List } from 'immutable'
import { camelCase } from 'lodash'

import type { SidebarModuleName } from '../../components/EditorSidebar'

export type ViewActionTypes =
  | SetViewBusyStateAction
  | UpdateSuggestedSearchAction
  | UpdateSuggestedSearchSelectionAction
  | ShowNotificationAction
  | HideNotificationAction
  | UpdateViewAction
  | DeclineCookieConsentAction
  | SetViewErrorAction
  | ClearViewErrorAction
  | SetMboMenuAction
  | SetMboMenuModeAction
  | SetIsThemePreviewAction
  | SetIsUnsupportedBrowserAction
  | SetHasThemeLoadedAction
  | ActivateEditorSidebarModuleAction
  | SetThemeNotificationAction
  | SetInterfaceLanguageAction
  | ResolvedApiAction<SubmitContactForm>

let nextNotificationId = 0

type SetViewBusyStateAction = {
  type: typeof SET_VIEW_BUSY_STATE
  busyState: boolean
}
export const SET_VIEW_BUSY_STATE = 'SET_VIEW_BUSY_STATE'
export function setViewBusyState(busyState: boolean): SetViewBusyStateAction {
  return {
    type: SET_VIEW_BUSY_STATE,
    busyState,
  }
}

type UpdateSuggestedSearchAction = {
  type: typeof UPDATE_SUGGESTED_SEARCH
  namespace: string
  searchTerm: string
  results: List<ImmutableMap> | null
}
export const UPDATE_SUGGESTED_SEARCH = 'UPDATE_SUGGESTED_SEARCH'
export function updateSuggestedSearch(
  namespace: string,
  searchTerm: string,
  results: List<ImmutableMap>,
): UpdateSuggestedSearchAction {
  return {
    type: UPDATE_SUGGESTED_SEARCH,
    namespace,
    searchTerm,
    results,
  }
}

type UpdateSuggestedSearchSelectionAction = {
  type: typeof UPDATE_SUGGESTED_SEARCH_SELECTION
  namespace: string
  selected: number
}
export const UPDATE_SUGGESTED_SEARCH_SELECTION = 'UPDATE_SUGGESTED_SEARCH_SELECTION'
export function updateSuggestedSearchSelection(
  namespace: string,
  selected: number,
): UpdateSuggestedSearchSelectionAction {
  return {
    type: UPDATE_SUGGESTED_SEARCH_SELECTION,
    namespace,
    selected,
  }
}

type ShowNotificationAction = {
  type: typeof SHOW_NOTIFICATION
  id: number
  level: string
  message: string
}
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export function showNotification(level: string, message: string): ShowNotificationAction {
  return {
    type: SHOW_NOTIFICATION,
    id: nextNotificationId++, // eslint-disable-line no-plusplus
    level,
    message,
  }
}

type HideNotificationAction = {
  type: typeof HIDE_NOTIFICATION
  id: string | number
}
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'
export function hideNotification(notificationId: string | number): HideNotificationAction {
  return {
    type: HIDE_NOTIFICATION,
    id: notificationId,
  }
}

type UpdateViewAction = {
  type: typeof UPDATE_VIEW
  meta: View.Meta[]
  link: View.Link[]
}
export const UPDATE_VIEW = 'UPDATE_VIEW'
export function updateView(meta: View.Meta[], link: View.Link[]): UpdateViewAction {
  return {
    type: UPDATE_VIEW,
    meta,
    link,
  }
}

type DeclineCookieConsentAction = {
  type: typeof DECLINE_COOKIE_CONSENT
}
export const DECLINE_COOKIE_CONSENT = 'DECLINE_COOKIE_CONSENT'
export function declineCookieConsent(): DeclineCookieConsentAction {
  return {
    type: DECLINE_COOKIE_CONSENT,
  }
}

type SetViewErrorAction = {
  type: typeof SET_VIEW_ERROR
  message: string
  statusCode: number
  requestId: string | null
}
export const SET_VIEW_ERROR = 'SET_VIEW_ERROR'
export function setViewError(message: string, statusCode = 500, requestId = null): SetViewErrorAction {
  return {
    type: SET_VIEW_ERROR,
    message,
    statusCode,
    requestId,
  }
}

type ClearViewErrorAction = {
  type: typeof CLEAR_VIEW_ERROR
}
export const CLEAR_VIEW_ERROR = 'CLEAR_VIEW_ERROR'
export function clearViewError(): ClearViewErrorAction {
  return {
    type: CLEAR_VIEW_ERROR,
  }
}

type SetMboMenuAction = {
  type: typeof SET_MBO_MENU
  mboMenu: any[]
}
export const SET_MBO_MENU = 'SET_MBO_MENU'
export function setMboMenu(mboMenu: any[]): SetMboMenuAction {
  return {
    type: SET_MBO_MENU,
    mboMenu,
  }
}

type SetMboMenuModeAction = {
  type: typeof SET_MBO_MENU_MODE
  mboMenuMode: boolean
}
export const SET_MBO_MENU_MODE = 'SET_MBO_MENU_MODE'
export function setMboMenuMode(mboMenuMode: boolean): SetMboMenuModeAction {
  return {
    type: SET_MBO_MENU_MODE,
    mboMenuMode,
  }
}

type SetIsThemePreviewAction = {
  type: typeof SET_IS_THEME_PREVIEW
  isThemePreview: boolean
}
export const SET_IS_THEME_PREVIEW = 'SET_IS_THEME_PREVIEW'
export function setIsThemePreview(isThemePreview: boolean): SetIsThemePreviewAction {
  return {
    type: SET_IS_THEME_PREVIEW,
    isThemePreview,
  }
}

type SetIsUnsupportedBrowserAction = {
  type: typeof SET_IS_UNSUPPORTED_BROWSER
  isUnsupportedBrowser: boolean
}
export const SET_IS_UNSUPPORTED_BROWSER = 'SET_IS_UNSUPPORTED_BROWSER'
export function setIsUnsupportedBrowser(isUnsupportedBrowser: boolean): SetIsUnsupportedBrowserAction {
  return {
    type: SET_IS_UNSUPPORTED_BROWSER,
    isUnsupportedBrowser,
  }
}

type SetHasThemeLoadedAction = {
  type: typeof SET_HAS_THEME_LOADED
  hasThemeLoaded: boolean | undefined
}
export const SET_HAS_THEME_LOADED = 'SET_HAS_THEME_LOADED'
export function setHasThemeLoaded(hasThemeLoaded: boolean | undefined): SetHasThemeLoadedAction {
  return {
    type: SET_HAS_THEME_LOADED,
    hasThemeLoaded,
  }
}

type ActivateEditorSidebarModuleAction = {
  type: typeof ACTIVATE_EDITOR_SIDEBAR_MODULE
  moduleName: SidebarModuleName
}
export const ACTIVATE_EDITOR_SIDEBAR_MODULE = 'ACTIVATE_EDITOR_SIDEBAR_MODULE'
export function activateEditorSidebarModule(moduleName: SidebarModuleName): ActivateEditorSidebarModuleAction {
  return {
    type: ACTIVATE_EDITOR_SIDEBAR_MODULE,
    moduleName,
  }
}

type SetThemeNotificationAction = {
  type: typeof SET_THEME_NOTIFICATION
  notification: {
    id: string
    message: any
    type: string
  }
}
export const SET_THEME_NOTIFICATION = 'SET_THEME_NOTIFICATION'
export function setThemeNotification(
  notification: SetThemeNotificationAction['notification'],
): SetThemeNotificationAction {
  return {
    type: SET_THEME_NOTIFICATION,
    notification,
  }
}

type SetInterfaceLanguageAction = {
  type: typeof SET_INTERFACE_LANGUAGE
  language: string
}
export const SET_INTERFACE_LANGUAGE = 'SET_INTERFACE_LANGUAGE'
export function setInterfaceLanguage(language: string): SetInterfaceLanguageAction {
  return {
    type: SET_INTERFACE_LANGUAGE,
    language,
  }
}

export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE'
export const SWITCH_LANGUAGE_FAILURE = 'SWITCH_LANGUAGE_FAILURE'
export function switchLanguage(locale: string): GlobalAction {
  return async (dispatch, getState, api) => {
    dispatch({
      type: SWITCH_LANGUAGE,
      locale,
    })

    const currentStoreState = getState()
    const pathname = currentStoreState.getIn(['location', 'pathname'])
    const categories = currentStoreState.get('categories')
    const products = currentStoreState.get('products')
    const pages = currentStoreState.get('pages')
    const sellingCountryId = currentStoreState.getIn(['shop', 'sellingCountryId']) || null

    const [, type, identifier] = pathname.match(/\/(\w)\/(.*)/) || []

    // get the new language's slug for the page we're currently at
    const gettingSlug = (async function () {
      switch (type) {
        case 'c': {
          const categoryId = categories.find((category) => category.get('slug') === identifier).get('categoryId')

          try {
            const { data } = await api.get(`/api/v2/categories/${categoryId}`, { params: { locale } })

            if (!data.slug) throw new Error(`Category ${categoryId} doesn't have a slug`)

            return `c/${data.slug}`
          } catch {
            // E.g. the category doesn't yet exist in our database because the navigation endpoint
            // has not been called for all locales since it was created.
            return `c/${categoryId}`
          }
        }
        case 'p': {
          const productId = products.getIn([identifier, 'productId'])

          const { data } = await api.get(`/api/v2/products/${productId}`, { params: { locale, sellingCountryId } })

          return `p/${data.slug}`
        }
        case 'i': {
          const pageSlugs = pages.getIn([identifier, 'slugs'])

          // Currently, the condition only applies to Beyond multilanguage shops.
          // In Now multilanguage shops, each page is independent and there are
          // no page variants for each locale (i.e. no "slugs" in the page state).
          if (pageSlugs) {
            const slug = pageSlugs.find((slug) => slug.get('locale') === locale).get('slug')
            return `i/${slug}`
          }

          // No locale page variant (e.g. Now multilanguage shops).
          // Return empty to redirect to the home page.
          return ''
        }
        case 'l':
          return `l/${identifier}`
        case 'o':
          return `o/${identifier}`
        default: {
          if (pathname.endsWith('/cart')) {
            return 'cart'
          } else if (pathname.endsWith('/search')) {
            return 'search'
          } else if (/\/customer-account(\/.+)?$/.test(pathname)) {
            return pathname.slice(1)
          } else {
            // Return empty to redirect to the home page.
            return ''
          }
        }
      }
    })()

    try {
      const slug = await gettingSlug
      const isEditor = Boolean(currentStoreState.getIn(['view', 'editorMode']))
      const shopDefaultLocale = currentStoreState.getIn(['shop', 'defaultLocale'])

      const newSearchParams = new URLSearchParams(currentStoreState.getIn(['location', 'search']))

      let newPathname = ''
      if (isEditor) newPathname += '/editor'
      if (locale !== shopDefaultLocale) newPathname += `/${locale.substring(0, 2)}`
      if (slug) newPathname += `/${slug}`
      if (!newPathname) newPathname = '/'

      if (isEditor) {
        // The editor token is updated approx. every 3 minutes via window.postMessage() (see app.js),
        // but the token isn't updated in the page URL, only in `api.defaults.headers.common.Authorization`.
        const tokenMatch = /Bearer (.*)/.exec(api.defaults.headers.common.Authorization as string)
        const newToken = tokenMatch && tokenMatch[1]
        if (newToken) newSearchParams.set('token', newToken)

        // Retain currently opened editor sidebar menu section.
        const openedEditorSidebarMenuSection = currentStoreState.getIn(['view', 'editorSidebar', 'activeModule'])
        newSearchParams.set('openMenuSection', camelCase(openedEditorSidebarMenuSection))
      }

      window.location.assign(newPathname + (newSearchParams.toString() ? `?${newSearchParams.toString()}` : ''))
    } catch (error) {
      return dispatch({ type: SWITCH_LANGUAGE_FAILURE, locale, error })
    }
  }
}

type SubmitContactForm = {
  type: typeof SUBMIT_CONTACT_FORM
  payload: {
    name: string
    email: string
    message: string
  }
}
export const SUBMIT_CONTACT_FORM = 'SUBMIT_CONTACT_FORM'
export const SUBMIT_CONTACT_FORM_SUCCESS = 'SUBMIT_CONTACT_FORM_SUCCESS'
export const SUBMIT_CONTACT_FORM_FAILURE = 'SUBMIT_CONTACT_FORM_FAILURE'
export function submitContactForm(
  payload: SubmitContactForm['payload'],
  csrfToken: string,
  options?: ActionOptions,
): ApiAction<SubmitContactForm> {
  return {
    type: SUBMIT_CONTACT_FORM,
    idempotent: false,
    payload,
    callApi: (api, payload) => {
      const config = {
        headers: {
          'x-csrf-token': csrfToken,
        },
      }

      return api.post('/api/v2/contactform', payload, config)
    },
    options,
  }
}
