import type { HTMLAttributes, ReactElement } from 'react'

import { BulkPricesDataStructure } from './BulkPrices'

export function getProductMicrodata(
  product: Frontend.Product,
): Pick<HTMLAttributes<HTMLElement>, 'itemScope' | 'itemType'> {
  // For Google a product requires either offers, review or aggregateRating.
  // An offer is not valid without any price information. So we have to omit the structured data for product if there is no price.
  // See https://developers.google.com/search/docs/advanced/structured-data/product#product
  const hasDifferentVariationPrices =
    product.lowestPrice && product.highestPrice && product.lowestPrice.amount !== product.highestPrice.amount
  const hasAnyPrice = Boolean(product.price || hasDifferentVariationPrices)
  return hasAnyPrice ? { itemScope: true, itemType: 'http://schema.org/Product' } : {}
}

type Props = { product: Frontend.Product }
export function PriceStructuredData({ product }: Props): ReactElement | null {
  const hasDifferentVariationPrices =
    product.lowestPrice && product.highestPrice && product.lowestPrice.amount !== product.highestPrice.amount

  if (product.price)
    return (
      <div itemScope itemProp="offers" itemType="http://schema.org/Offer">
        <meta itemProp="url" content={product.href} />
        <meta itemProp="price" content={product.price.amount.toString()} />
        <meta itemProp="priceCurrency" content={product.price.currency} />
        {product.stockLevelMicrodata && (
          <link itemProp="availability" href={`http://schema.org/${product.stockLevelMicrodata}`} />
        )}
        <link itemProp="itemCondition" href={`http://schema.org/${product.conditionMicrodata}`} />
        <BulkPricesDataStructure bulkPrices={product.bulkPrices} />
      </div>
    )
  if (hasDifferentVariationPrices)
    return (
      <div itemScope itemProp="offers" itemType="http://schema.org/AggregateOffer">
        <meta itemProp="lowPrice" content={product.lowestPrice?.amount.toString()} />
        <meta itemProp="highPrice" content={product.highestPrice?.amount.toString()} />
        <meta itemProp="priceCurrency" content={product.lowestPrice?.currency} />
        {product.variations && <meta itemProp="offerCount" content={product.variations.items.length.toString()} />}
        {product.stockLevelMicrodata && (
          <link itemProp="availability" href={`http://schema.org/${product.stockLevelMicrodata}`} />
        )}
        <link itemProp="itemCondition" href={`http://schema.org/${product.conditionMicrodata}`} />
      </div>
    )

  return null
}
