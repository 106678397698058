import type { ChangeEvent, KeyboardEvent, ReactElement } from 'react'
import { debounce } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import Range from 'rc-slider/lib/Range'

type Range = { min: number; max: number }

type Props = {
  className: string
  range: Range
  value?: Range
  onChange({ min, max }: Range): void
  unit?: string
}

export default function InputSlider({ className, range, value, onChange, unit = '€' }: Props): ReactElement {
  const rangeMin = range.min
  const rangeMax = range.max
  const selectedMin = value?.min ?? rangeMin
  const selectedMax = value?.max ?? rangeMax

  const [selection, setSelection] = useState({
    min: selectedMin,
    max: selectedMax,
  })

  const [inputMinValue, setInputMinValue] = useState(selectedMin)
  const [inputMaxValue, setInputMaxValue] = useState(selectedMax)

  useEffect(() => {
    setInputMinValue(selectedMin)
    setSelection({ min: selectedMin, max: selectedMax })
  }, [selectedMin]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInputMaxValue(selectedMax)
    setSelection({ min: selectedMin, max: selectedMax })
  }, [selectedMax]) // eslint-disable-line react-hooks/exhaustive-deps

  // Store debounced functions inside a Ref to prevent them from re-initialization and losing their timeout.
  const savePriceSelection = useRef(
    debounce((min: number, max: number) => {
      onChange({ min, max })
    }, 1000),
  )

  const resetInputMinValue = useRef(
    debounce((min: number) => {
      setInputMinValue(min)
    }, 1500),
  )

  const resetInputMaxValue = useRef(
    debounce((max: number) => {
      setInputMaxValue(max)
    }, 1500),
  )

  const preventNonNumericInput = (evt: KeyboardEvent<HTMLInputElement>) => {
    if (/\D/.test(evt.key)) evt.preventDefault()
  }

  const handleSliderChange = ([min, max]: [number, number]) => {
    setSelection({ min, max })
    setInputMinValue(min)
    setInputMaxValue(max)
    savePriceSelection.current(min, max)
  }

  const handleInputMinChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const min = parseInt(evt.target.value)

    setInputMinValue(min)

    if (!isNaN(min)) {
      if (min >= rangeMin && min <= selectedMax) {
        resetInputMinValue.current.cancel()
        setSelection(({ max }) => ({ min, max }))
        savePriceSelection.current(min, selectedMax)
      } else {
        resetInputMinValue.current(selectedMin)
      }
    }
  }

  const handleInputMaxChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const max = parseInt(evt.target.value)

    setInputMaxValue(max)

    if (!isNaN(max)) {
      if (max <= rangeMax && max > selectedMin) {
        resetInputMaxValue.current.cancel()
        setSelection(({ min }) => ({ min, max }))
        savePriceSelection.current(selectedMin, max)
      } else {
        resetInputMaxValue.current(selectedMax)
      }
    }
  }

  const handleInputMinKeyUp = (evt: KeyboardEvent<HTMLInputElement>) => {
    const min = parseInt(evt.currentTarget.value)

    if (evt.code === 'Enter' && !isNaN(min) && min >= rangeMin && min <= selectedMax) {
      savePriceSelection.current(min, selectedMax)
    }
  }

  const handleInputMaxKeyUp = (evt: KeyboardEvent<HTMLInputElement>) => {
    const max = parseInt(evt.currentTarget.value)

    if (evt.code === 'Enter' && !isNaN(max) && max <= rangeMax && max >= selectedMin) {
      savePriceSelection.current(selectedMin, max)
    }
  }

  return (
    <>
      <Range
        className={className}
        min={rangeMin}
        max={rangeMax}
        step={1}
        onChange={handleSliderChange}
        value={[selection.min, selection.max]}
        allowCross={false}
        tabIndex={[-1, -1]}
      />
      <div className="faceted-search-slider-input">
        <label>
          <input
            type="number"
            min={rangeMin}
            max={rangeMax}
            value={isNaN(inputMinValue) ? '' : inputMinValue}
            onKeyPress={preventNonNumericInput}
            onKeyUp={handleInputMinKeyUp}
            onChange={handleInputMinChange}
          />
          {`${unit}`}
        </label>
        <span>-</span>
        <label>
          <input
            type="number"
            min={rangeMin}
            max={rangeMax}
            value={isNaN(inputMaxValue) ? '' : inputMaxValue}
            onKeyPress={preventNonNumericInput}
            onKeyUp={handleInputMaxKeyUp}
            onChange={handleInputMaxChange}
          />
          {`${unit}`}
        </label>
      </div>
    </>
  )
}
