import { fromJS } from 'immutable'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import cc from 'classcat'

import type { AvailableSortings } from '../../utils/pageAndSort'
import { GotoContext } from '../GotoProvider'
import type { ProductDataType } from './Workspace/plugins/category/CategoryPlugin'
import { addLegacyProductProperties, track } from '../../utils/tracking'
import { addProductsForCategoryAsync, buildFilterArray } from '../../store/actions'
import { getPlain } from '../../store/utils'
import { pagingDefaults, sortingDefaults } from '../../utils/pageAndSort'
import { useIsAboveView } from './ToolbarTop'
import ProductPage from './Workspace/plugins/category/ProductPage'
import ScrollToTopButton from '../ScrollToTopButton'
import Sorting from './Workspace/plugins/category/Sorting'
import useEffectExceptMount from '../../utils/hooks/useEffectExceptMount'
import usePrevious from '../../utils/hooks/usePrevious'

type Props = {
  productData: ProductDataType
  categoryData: ImmutableMap | Core.Page
  pageSize: number
  sortingOptions: Partial<AvailableSortings>
  isBusy?: boolean
  hideTopbar?: boolean
} & TranslateProps

function isHomePage(pathname: string): boolean {
  return /^\/([a-z]{2})?$/.test(pathname)
}

export default function CategoryContent({
  categoryData,
  productData,
  pageSize,
  sortingOptions,
  t,
  isBusy = false,
  hideTopbar = false,
}: Props): React.ReactElement {
  const { gotoState, replace } = React.useContext(GotoContext)
  const [isLoading, setIsLoading] = React.useState(false)
  const [renderUpButton, setRenderUpButton] = React.useState(false)
  const [refTopDiv, isAboveView] = useIsAboveView()
  const dispatch = useDispatch<GlobalDispatch>()

  const isBeyond = useSelector<State, boolean>((state) => Boolean(state.getIn(['shop', 'beyond'])))
  const location = useSelector<State, ImmutableMap>((state) => state.get('location'))
  const isViewBusy = useSelector<State, boolean>((state) => Boolean(state.getIn(['view', 'busy'])))
  const query: ImmutableMap = location?.get('query')
  const sort = query.get('sort', productData.defaultSort || sortingDefaults.sort) as string
  const page = parseInt(query.get('page', pagingDefaults.page))
  const searchFacetFilters = buildFilterArray(query.toJS())

  const totalNumberOfProducts = productData.totalNumberOfProducts || 0
  const pageCount = Math.ceil(totalNumberOfProducts / pageSize)

  const immutableCategoryData: ImmutableMap = fromJS(categoryData)
  const plainCategoryData: Core.Page & {
    facetedSearchShowFacetsOnCategory?: boolean
  } = getPlain(categoryData)

  const categoryId = productData.categoryId || ''
  // In Beyond, the `imageSize` option is stored with the category plugin,
  // whereas in Now it is stored on the category page settings.
  const imageSize =
    immutableCategoryData.get('imageSize') || immutableCategoryData.getIn(['settings', 'imageSize']) || 'M'

  // Tracking effects
  const prevProductData = usePrevious(productData) || productData
  React.useEffect(() => {
    track('category:view', {
      category: plainCategoryData,
      products: productData.products.map((product) => addLegacyProductProperties(product, isBeyond)),
    })
    // Only track it once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffectExceptMount(() => {
    const isSameCategory = productData.categoryId === prevProductData.categoryId
    const newProductsAmount = productData.products.length - prevProductData.products.length
    const sortChanged = productData.sort && !isEqual(productData.sort, prevProductData.sort)

    if (isSameCategory && newProductsAmount > 0) {
      // New products came in via click on load more button.
      // Track category with only the new product items.
      track('category:view', {
        category: plainCategoryData,
        products: productData.products
          .slice(-newProductsAmount)
          .map((product) => addLegacyProductProperties(product, isBeyond)),
      })
    } else if (
      // The "Sort by" option has been changed.
      (isSameCategory && sortChanged) ||
      // The category actually changed
      !isSameCategory
    ) {
      // Track category with the product items on the page.
      track('category:view', {
        category: plainCategoryData,
        products: productData.products.map((product) => addLegacyProductProperties(product, isBeyond)),
      })
    }
  }, [productData, location, immutableCategoryData, prevProductData])
  // Tracking effects end

  React.useEffect(() => {
    setRenderUpButton(isAboveView)
  }, [isAboveView])

  const loadedPageCount = Math.ceil(productData.products.length / pageSize)

  const updateCurrentUrl = (page: number) => {
    const searchParams = new URLSearchParams()
    const query = location.get('query').toJS() as { [key: string]: string | string[] }
    Object.entries(query).forEach(([key, param]) => {
      if (Array.isArray(param)) {
        param.forEach((value) => searchParams.append(key, value))
      } else {
        searchParams.append(key, param)
      }
    })

    searchParams.set('sort', sort)
    searchParams.set('page', String(page))
    // Ensure that all search parameters are keeped in order
    searchParams.sort()
    const url = location.get('pathname') + '?' + searchParams.toString()
    replace(url)
  }
  const changeSorting = React.useCallback(
    (sort: string) => {
      const searchParams = new URLSearchParams()
      const query = location.get('query').toJS() as { [key: string]: string | string[] }
      Object.entries(query).forEach(([key, param]) => {
        if (Array.isArray(param)) {
          param.forEach((value) => searchParams.append(key, value))
        } else {
          searchParams.append(key, param)
        }
      })
      searchParams.set('sort', sort)
      searchParams.set('page', '1')
      // Ensure that all search parameters are keeped in order
      searchParams.sort()

      gotoState({
        pathname: location.get('pathname'),
        search: '?' + searchParams.toString(),
        state: { scrollToTop: false },
      })
    },
    [gotoState, location],
  )

  const loadMoreProducts = async () => {
    if (loadedPageCount >= pageCount) return

    const newPage = page + 1

    if (isHomePage(location.get('pathname')) || !plainCategoryData.facetedSearchShowFacetsOnCategory) {
      setIsLoading(true)

      updateCurrentUrl(newPage)

      // this is not the prettiest of fixes and should be changed when
      // the oppertunity is possible. The reason for only updating if it
      // is beyond is because on now, it will automatically update
      // itself when we set teh URL above. Beyond does not use the
      // `Category` component, which does the updating.
      if (isBeyond) {
        await dispatch(
          addProductsForCategoryAsync(
            categoryId,
            { page: newPage, resultsPerPage: pageSize, sort },
            searchFacetFilters,
          ),
        )
      }

      setIsLoading(false)
    } else {
      updateCurrentUrl(newPage)
    }
  }

  const trackProductClick = (product: Core.Product, productIndex: number) => {
    track('product:click', {
      type: 'category',
      detail: immutableCategoryData.get('title'),
      product: addLegacyProductProperties(product, isBeyond),
      productIndex,
    })
  }

  // `isViewBusy` is only needed because we skip the
  // `addProductsForCateoryAsync` when not in beyond. This makes it so
  // that the button shows the spinner even though it is not this
  // component doing the loading work. For Beyond, we want the old logic
  const showLoadingIndication = isBusy || isLoading || (!isBeyond && isViewBusy)

  return (
    <>
      {!hideTopbar && totalNumberOfProducts > 1 && (
        <div className="toolbar-top">
          <Sorting t={t} onChange={changeSorting} options={sortingOptions} value={sort} />
        </div>
      )}
      {totalNumberOfProducts > 0 && (
        <>
          <div ref={refTopDiv} />
          <ProductPage
            products={productData.products}
            trackProductClick={trackProductClick}
            loadMoreProducts={loadMoreProducts}
            imageSize={imageSize}
            pageSize={pageSize}
          />

          {loadedPageCount < pageCount && (
            <div className="product-list-footer toolbar-bottom">
              {loadedPageCount === 1 ? (
                <button
                  disabled={showLoadingIndication}
                  type="button"
                  className={cc(['show-more-button', { pending: showLoadingIndication }])}
                  onClick={loadMoreProducts}
                >
                  {t('components.collectionComponent.showMoreButton.label')}
                </button>
              ) : (
                showLoadingIndication && <button disabled type="button" className="button-spinner-only pending" />
              )}
            </div>
          )}
          {renderUpButton && <ScrollToTopButton />}
        </>
      )}
    </>
  )
}
