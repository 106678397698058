import { useRef, useState } from 'react'

import { ImageCanvas } from './ImageCanvas'
import { allowedMimeTypes, mimeTypeMap } from './templateComponents/Workspace/daliConfig'

const zoomRatio = 0.1
const initialZoomLevel = 1

type editState = {
  aspectRatio?: number
  position: [number, number]
  zoom: number
}

type ImageEditorProps = Readonly<{
  /** The source image to be edited */
  src: string

  /** The initial edit state for the image editor */
  edit: editState

  /** Callback to handle the initial render of the image, e.g. initial auto-edit */
  onInit: (imageFile: File) => Promise<void>

  /** Callback to handle changes to the image, i.e. save */
  onChange: (edit: editState, imageFile: File, newImageSourceFile?: File) => Promise<void>

  /** Callback to handle canceling the editing */
  onCancel: () => void
}>

/** An image editor component that allows the user to edit an image. */
export function ImageEditor({ src, edit, onInit, onChange, onCancel }: ImageEditorProps) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const sourceImageRef = useRef<HTMLImageElement | null>(null)

  const [imageSrc, setImageSrc] = useState(src)
  const [filename, setFilename] = useState(getFilename(src))

  const [isReady, setIsReady] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(edit.zoom)
  const [aspectRatio, setAspectRatio] = useState(edit.aspectRatio)

  function zoomIn() {
    const newZoom = parseFloat((zoomLevel + zoomRatio).toFixed(1))
    setZoomLevel(newZoom)
  }

  function zoomOut() {
    const newZoom = parseFloat((zoomLevel - zoomRatio).toFixed(1))
    setZoomLevel(newZoom)
  }

  async function pending(callbackFn: () => Promise<void>) {
    setIsPending(true)
    try {
      await callbackFn()
    } finally {
      setIsPending(false)
    }
  }

  async function handleChange() {
    await pending(async () => {
      if (canvasRef.current && sourceImageRef.current) {
        const filePromises = [getFileFromCanvas(canvasRef.current, filename)]

        if (imageSrc.startsWith('data:')) {
          // File upload
          filePromises.push(getFileFromImage(sourceImageRef.current, filename))
        }

        const [imageFile, newImageSourceFile] = await Promise.all(filePromises)
        const currentEdit: editState = { aspectRatio, position: [0, 0], zoom: zoomLevel }

        await onChange(currentEdit, imageFile, newImageSourceFile)
      }
    })
  }

  return (
    <>
      {isReady && (
        <fieldset data-ep-editor disabled={isPending} style={{ position: 'absolute', top: 0, left: 0 }}>
          <legend>Edit Image</legend>
          <label>
            Upload{' '}
            <input
              type="file"
              accept={allowedMimeTypes.join(',')}
              onChange={(event) => {
                const file = event.target.files?.[0]
                if (file) {
                  const reader = new FileReader()
                  reader.onload = () => {
                    setImageSrc(reader.result as string)
                    setFilename(file.name)
                    setZoomLevel(initialZoomLevel)
                  }
                  reader.readAsDataURL(file)
                }
              }}
            />
          </label>
          <button type="button" onClick={zoomOut} disabled={zoomLevel === initialZoomLevel}>
            Zoom Out
          </button>
          <button type="button" onClick={zoomIn}>
            Zoom In
          </button>
          <button
            type="button"
            onClick={() => {
              onCancel()
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => {
              handleChange()
            }}
          >
            Save
          </button>
          <div>
            <label>
              Aspect ratio:{' '}
              <select
                defaultValue={aspectRatio}
                onChange={(event) => {
                  setAspectRatio(parseFloat(event.target.value))
                }}
              >
                {Object.entries(aspectRatioMap).map(([label, value]) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
                {sourceImageRef.current && (
                  <option value={sourceImageRef.current.width / sourceImageRef.current.height}>Original</option>
                )}
              </select>
            </label>
          </div>
        </fieldset>
      )}
      <ImageCanvas
        src={imageSrc}
        aspectRatio={aspectRatio}
        zoomLevel={zoomLevel}
        onUpdate={(canvas, sourceImage) => {
          canvasRef.current = canvas
          sourceImageRef.current = sourceImage

          if (!aspectRatio) {
            // Default to the aspect ratio of the source image
            setAspectRatio(sourceImage.width / sourceImage.height)
          }

          if (!isReady) {
            setIsReady(true)
            pending(async () => onInit(await getFileFromCanvas(canvas, filename)))
          }
        }}
      />
    </>
  )
}

async function getFileFromCanvas(canvas: HTMLCanvasElement, filename: string) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mimeType = mimeTypeMap[filename.toLowerCase().split('.').pop()!]
  const blob = await new Promise<Blob | null>((resolve) => {
    canvas.toBlob((blob) => resolve(blob), mimeType)
  })

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return new File([blob!], filename, { type: mimeType })
}

async function getFileFromImage(image: HTMLImageElement, filename: string) {
  const canvas = document.createElement('canvas')
  canvas.width = image.width
  canvas.height = image.height
  canvas.getContext('2d')?.drawImage(image, 0, 0)
  return getFileFromCanvas(canvas, filename)
}

function getFilename(src: string) {
  const url = new URL(src, window.location.origin)
  return url.searchParams.has('remote')
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      decodeURIComponent(url.searchParams.get('remote')!.split('/').pop()!)
    : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      decodeURIComponent(url.pathname.split('/').pop()!)
}

const aspectRatioMap = {
  '1:1': 1,
  '2:3': 2 / 3,
  '4:3': 4 / 3,
  '16:9': 16 / 9,
  '3:2': 3 / 2,
}
