import PropTypes from 'prop-types'
import React from 'react'
import ReactModal from 'react-modal'
import cc from 'classcat'

// used by ReactModal
const CLOSE_TIMEOUT = 500

export default function SlideLayer({
  className,
  overlayClassName,
  closeClassName,
  direction = 'right',
  isOpen,
  closeElement,
  title,
  children,
  onRequestClose,
  onAfterOpen,
  parentSelector = () => document.querySelector('.body'),
}) {
  return (
    <ReactModal
      className={cc(['slide-layer', `slide-layer-direction-${direction}`, { [className]: className }])}
      overlayClassName={cc(['slide-layer-overlay', { [overlayClassName]: overlayClassName }])}
      closeTimeoutMS={CLOSE_TIMEOUT}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      parentSelector={parentSelector}
      aria={{ labelledby: 'slide-layer-title' }}
      ariaHideApp={false}
    >
      <div className="slide-layer-header">
        <button
          className={cc(['slide-layer-header-close', { [closeClassName]: closeClassName }])}
          onClick={onRequestClose}
        >
          {closeElement || (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"
              />
            </svg>
          )}
        </button>
        <div id="slide-layer-title" className="slide-layer-header-title">
          {title}
        </div>
      </div>
      <div className="slide-layer-content">{children}</div>
    </ReactModal>
  )
}

SlideLayer.propTypes = {
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  closeClassName: PropTypes.string,
  direction: PropTypes.oneOf(['left', 'right']),
  isOpen: PropTypes.bool.isRequired,
  closeElement: PropTypes.node,
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onRequestClose: PropTypes.func,
  onAfterOpen: PropTypes.func,
  parentSelector: PropTypes.func,
}
