import Immutable from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'

import { PageOrCategoryContext } from '../../views/Category'
import { updateCategoryContent, updateLegalPageContent, updatePageContent } from '../../store/actions'
import Element from './Workspace/Element'
import daliConfig from './Workspace/daliConfig'
import withReduxContext from '../../utils/withReduxContext'

const PluginRaw = ({ _id, type, store }) => {
  const pageOrCategory = React.useContext(PageOrCategoryContext)
  const shopSlug = store.getState().getIn(['shop', 'slug'])
  const editorMode = store.getState().getIn(['view', 'editorMode'])
  const mboBaseUrl = store.getState().getIn(['shop', 'mboBaseUrl'])
  const element =
    pageOrCategory
      .get('content')
      .get('elements')
      .filter((e) => e.get('_id') === _id)
      .first() || Immutable.fromJS({ _id, type, data: {} })

  return (
    <Element
      config={daliConfig(editorMode, store.api, shopSlug)}
      editorView={editorMode}
      element={element}
      onElementChange={(newElement) => {
        const oldContent = pageOrCategory.get('content')
        const elementIndex = oldContent.get('elements').findIndex((e) => e.get('_id') === _id)
        const newContent =
          elementIndex >= 0
            ? oldContent.setIn(['elements', elementIndex], newElement)
            : oldContent.set('elements', oldContent.get('elements').push(newElement))

        const updateInstructions = pageOrCategory.get('categoryId')
          ? [pageOrCategory.get('categoryId'), newContent]
          : [pageOrCategory.get('slug'), newContent]

        const updateAction = (function () {
          switch (pageOrCategory.get('type')) {
            case 'blank':
              return updatePageContent
            case 'legal':
              return updateLegalPageContent
            case 'category':
              return updateCategoryContent
            default:
              return (_) => _
          }
        })()

        store.dispatch(updateAction.apply(null, updateInstructions))

        if (pageOrCategory.get('type') === 'legal') {
          // Auto-checks the "Add legal content" to-do in the BYD cockpit's dashboard checklist and setup guide
          const payload = { regularShop: 'informationConfiguration.createLegalContent' }

          if (pageOrCategory.get('title') === 'enumerations.legalPages.legalNotice') {
            // Auto-checks the "Provide a legal notice" to-do in the BYD cockpit's dashboard checklist and setup guide
            payload.websiteOnly = 'legalSection.createImprint'
          } else if (pageOrCategory.get('title') === 'enumerations.legalPages.privacyPolicy') {
            // Auto-checks the "Provide a privacy policy" to-do in the BYD cockpit's dashboard checklist and setup guide
            payload.websiteOnly = 'legalSection.createPrivacyPolicy'
          }
          window.parent?.postMessage({ type: 'CHECKLIST', payload }, mboBaseUrl)
        }
      }}
    />
  )
}
PluginRaw.propTypes = {
  _id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  store: PropTypes.object.isRequired,
}

export default withReduxContext(PluginRaw)
