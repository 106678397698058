type DataLayerEvent =
  | 'view_item_list'
  | 'select_item'
  | 'view_item'
  | 'add_to_cart'
  | 'add_to_wishlist'
  | 'remove_from_cart'
  | 'view_cart'
  | 'begin_checkout'
  | 'add_shipping_info'
  | 'add_payment_info'
  | 'purchase'
  | 'search'
  | 'login'
  | 'sign_up'

type DataLayer = {
  event?: DataLayerEvent
  // search
  search_term?: string
  // login and sign_up
  method?: string
  // ecommerce
  ecommerce?: null | {
    items: GA4Item[]
    currency?: string
    value?: number
    coupon?: string
    // shipping method
    shipping_tier?: string
    // payment method
    payment_type?: string
    // purchase
    transaction_id?: string
    affiliation?: string
    tax?: number
    shipping?: number
  }
}

export type GA4Item = Partial<{
  item_id: string
  item_name: string
  affiliation: string
  coupon: string
  currency: string
  discount: number
  index: number
  item_brand: string
  item_category: string
  item_category2: string
  item_category3: string
  item_category4: string
  item_category5: string
  item_list_id: string
  item_list_name: string
  item_variant: string
  location_id: string
  price: number
  quantity: number
}>

function cartItemsFromLineItems(
  item: Core.ProductLineItem | Frontend.Product,
  index?: number,
  listId?: string,
  listName?: string,
): GA4Item {
  if ('lineItemId' in item) {
    // Cart productLineItem
    return {
      index,
      item_id: item.sku,
      item_name: item.name,

      price: item.singleItemPrice.amount,
      currency: item.singleItemPrice.currency,
      quantity: item.quantity,

      discount: item.lineItemCouponDiscount?.amount,
      // We have no coupon information besides the discount
      // coupon: ?

      item_variant: item.variationString ?? undefined,

      item_list_id: listId,
      item_list_name: listName,
    }
  } else {
    // Core product
    return {
      index,
      item_id: item.sku,
      item_name: item.name,

      price: item.price?.amount,
      currency: item.price?.currency,

      item_brand: item.manufacturer ?? undefined,

      item_variant: item.productVariationValues ?? undefined,

      item_list_id: listId,
      item_list_name: listName,
    }
  }
}

function pushToDataLayer(event: DataLayerEvent, data: DataLayer) {
  // Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clear_the_ecommerce_object
  window.dataLayer?.push({ ecommerce: null })
  window.dataLayer?.push({ event, ...data })
}

export function initializeDataLayer() {
  window.dataLayer = window.dataLayer || []
  const target = window.eComEventTarget
  // Customer account
  target.addEventListener('customer:signIn', function () {
    pushToDataLayer('login', { method: 'email' })
  })
  target.addEventListener('customer:signUp', function () {
    pushToDataLayer('sign_up', { method: 'email' })
  })
  // Product view and interaction
  target.addEventListener('searchResults:view', function (event: CustomEvent<StorefrontEvents.SearchResultsView>) {
    const { products, query } = event.detail
    // Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item_list-gtm
    pushToDataLayer('view_item_list', {
      ecommerce: {
        items: products.map((product, index) => cartItemsFromLineItems(product, index, 'search', query.q)),
      },
    })
  })
  target.addEventListener('category:view', function (event: CustomEvent<StorefrontEvents.CategoryView>) {
    const { category, products } = event.detail
    // Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item_list-gtm
    pushToDataLayer('view_item_list', {
      ecommerce: {
        items: products.map((product, index) => cartItemsFromLineItems(product, index, category.guid, category.title)),
      },
    })
  })
  target.addEventListener('product:click', function (event: CustomEvent<StorefrontEvents.ProductClick>) {
    const { product, type, detail, productIndex } = event.detail
    let itemListName: string | undefined
    let itemListId: string | undefined
    if (type === 'category') itemListName = detail
    if (type === 'search') {
      itemListId = 'search'
      itemListName = detail
    }
    if (type === 'crossSelling') {
      itemListId = 'related_products'
      itemListName = detail
    }

    // Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#select_item-gtm
    pushToDataLayer('select_item', {
      ecommerce: {
        items: [cartItemsFromLineItems(product, productIndex, itemListId, itemListName)],
      },
    })
  })
  target.addEventListener('product:view', function (event: CustomEvent<StorefrontEvents.ProductView>) {
    const product = event.detail.product
    // Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item_details
    pushToDataLayer('view_item', {
      ecommerce: {
        items: [{ ...cartItemsFromLineItems(product), index: 0 }],
      },
    })
  })

  // Shopping cart interactions
  target.addEventListener('cart:add', function (event: CustomEvent<StorefrontEvents.CartAdd>) {
    const { product, quantity } = event.detail
    // Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_to_cart-gtm
    pushToDataLayer('add_to_cart', {
      ecommerce: {
        items: [{ ...cartItemsFromLineItems(product), index: 0, quantity }],
      },
    })
  })
  target.addEventListener('cart:setQuantity', function (event: CustomEvent<StorefrontEvents.CartSetQuantity>) {
    const { lineItem, quantity, quantityDelta } = event.detail
    // On removal the quantityDelta is undefined.
    if (quantity === 0 || quantityDelta < 0) {
      // Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#remove_from_cart-gtm
      pushToDataLayer('remove_from_cart', {
        ecommerce: {
          items: [
            {
              ...cartItemsFromLineItems(lineItem),
              index: 0,
              quantity: Math.abs(quantityDelta),
            },
          ],
        },
      })
    } else {
      // Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_to_cart-gtm
      pushToDataLayer('add_to_cart', {
        ecommerce: {
          items: [
            {
              ...cartItemsFromLineItems(lineItem),
              index: 0,
              quantity: Math.abs(quantityDelta),
            },
          ],
        },
      })
    }
  })
  target.addEventListener('cart:view', function (event: CustomEvent<StorefrontEvents.CartView>) {
    const cart = event.detail.cart
    // Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_cart-gtm
    pushToDataLayer('view_cart', {
      ecommerce: {
        currency: cart.grandTotal.currency,
        value: cart.grandTotal.amount,
        items: cart.productLineItems.map((product, index) => cartItemsFromLineItems(product, index)),
      },
    })
  })
  target.addEventListener('wishlist:add', function (event: CustomEvent<StorefrontEvents.WishlistAdd>) {
    const { product } = event.detail
    // Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_to_wishlist-gtm
    pushToDataLayer('add_to_wishlist', {
      ecommerce: {
        items: [{ index: 0, item_id: product.productId, quantity: product.quantity }],
      },
    })
  })

  // Checkout funnel
  target.addEventListener('cart:checkout', function (event: CustomEvent<StorefrontEvents.CartCheckout>) {
    const { cart } = event.detail
    // Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#begin_checkout-gtm
    pushToDataLayer('begin_checkout', {
      ecommerce: {
        currency: cart.grandTotal.currency,
        value: cart.grandTotal.amount,
        coupon: cart.couponCampaign?.name ?? undefined,
        items: cart.productLineItems.map((product, index) => cartItemsFromLineItems(product, index)),
      },
    })
  })
  target.addEventListener(
    'shippingMethod:select',
    function (event: CustomEvent<StorefrontEvents.ShippingMethodSelect>) {
      const { shippingMethod, pickupOption, cart } = event.detail
      // Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_shipping_info-gtm
      if (shippingMethod) {
        pushToDataLayer('add_shipping_info', {
          ecommerce: {
            currency: shippingMethod.lineItemPrice?.currency,
            value: shippingMethod.lineItemPrice?.amount,
            shipping_tier: shippingMethod.name,
            coupon: cart?.couponCampaign?.name ?? undefined,
            items: cart ? cart.productLineItems.map((product, index) => cartItemsFromLineItems(product, index)) : [],
          },
        })
      }
      if (pickupOption) {
        pushToDataLayer('add_shipping_info', {
          ecommerce: {
            currency: pickupOption.fixedPrice?.currency,
            value: pickupOption.fixedPrice?.amount,
            shipping_tier: pickupOption.name,
            coupon: cart?.couponCampaign?.name ?? undefined,
            items: cart ? cart.productLineItems.map((product, index) => cartItemsFromLineItems(product, index)) : [],
          },
        })
      }
    },
  )
  target.addEventListener('paymentMethod:select', function (event: CustomEvent<StorefrontEvents.PaymentMethodSelect>) {
    const { paymentMethod, cart } = event.detail
    // Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_payment_info-gtm
    pushToDataLayer('add_payment_info', {
      ecommerce: {
        currency: paymentMethod.lineItemPrice?.currency,
        value: paymentMethod.lineItemPrice?.amount,
        payment_type: paymentMethod.name,
        coupon: cart?.couponCampaign?.name ?? undefined,
        items: cart ? cart.productLineItems.map((product, index) => cartItemsFromLineItems(product, index)) : [],
      },
    })
  })
  target.addEventListener('order:completed', function (event: CustomEvent<LegacyEvents.OrderCompleted>) {
    const order = event.detail
    // Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#purchase-gtm
    order &&
      pushToDataLayer('purchase', {
        ecommerce: {
          transaction_id: order.orderNumber,
          affiliation: 'Online Store',
          value: Number(order.grandTotal),
          tax: Number(order.totalTax),
          shipping: order.shippingData?.price.amount ?? 0,
          currency: order.currencyId,
          coupon: order.couponCampaign?.name ?? undefined,
          items: order.lineItemContainer.productLineItems.map((item, index: number) => ({
            index,
            item_id: item.sku,
            item_name: item.name,
            quantity: item.quantity.amount,
            price: item.singleItemPrice.amount,
            currency: item.singleItemPrice.currency,
            item_variant: item.variationString,
          })),
        },
      })
  })
}
