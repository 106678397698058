import type { ReactElement } from 'react'
import cc from 'classcat'

import AccountIcon from '../../epages.base/AccountIcon'
import HeaderLogo from '../../epages.base/HeaderLogo'
import MiniCart from '../../epages.base/MiniCart'
import SearchForm from '../../epages.base/SearchForm'
import ThemeHeaderMenu from '../../epages.base/ThemeHeaderMenu'

type Props = any
export default function TwoRowsHeader(props: Props): ReactElement {
  const { shop, isPreview } = props

  return (
    <div className="site-header">
      <div className="section">
        <div className="wrapper">
          <header className="header">
            <div className={cc(['header-logo', shop.logoUrl && !shop.shouldHideLogo && 'header-logo-adjustments'])}>
              <HeaderLogo shop={shop} />
            </div>
            <div className="block">
              <div className="header-search">
                <SearchForm className="search-form" namespace="header" maxSuggestedItems={5} />
              </div>
              <AccountIcon accountUrl={shop.accountUrl} isPreview={isPreview} />
              <MiniCart cartUrl={shop.cartUrl} />
            </div>
          </header>
        </div>
      </div>
      <ThemeHeaderMenu {...props} />
    </div>
  )
}
