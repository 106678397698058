import type { ComponentType, ReactNode } from 'react'
import type { ParseKeys, TOptions, i18n } from 'i18next'
import { ReactReduxContext } from 'react-redux'
import React from 'react'
import hoistStatics from 'hoist-non-react-statics'
import once from 'lodash/once'

import { i18nContext } from '../components/withI18n'
import addScript from '../utils/addScript'
import storage from '../utils/localStorage'

const phraseAppConfig = {
  autoLowercase: false,
  fullReparse: true,
  projectId: '88ec01f7513fe09972786928e6fb01a8',
  prefix: '{{__',
  suffix: '__}}',
}

const initializePhraseAppEditor = once(function () {
  window.PHRASEAPP_CONFIG = phraseAppConfig
  addScript(`https://phraseapp.com/assets/in-context-editor/2.0/app.js?${Date.now()}`)
})

const namespaceRE = /^\w+:/

function shouldUsePhraseAppInContextEditor() {
  return (
    typeof window === 'object' &&
    (['phraseapp.pm.epages.com', 'phraseapp-beyond.pm.epages.com'].includes(window.location.host) ||
      storage.getItem('phraseapp') === '1')
  )
}

/**
 *  Creates "t" function which either uses `i18next.t` or decorated "t" for use
 *  with Phrase In-Context Editor.
 */
export function createT(i18next: i18n, isBeyondShop = false): TranslateProps['t'] {
  return function (key: ParseKeys, options?: TOptions) {
    const translationKey =
      isBeyondShop &&
      // check that a beyond specific key is available for this entry in this language
      i18next.exists(`${key}_BYD`) &&
      // find out if its value in the shop language is not equal to its value in US English, which is the fallback
      // language. A falsy return value would suggest that the beyond specific value is actually null
      (i18next.language === 'en' ||
        i18next.t(`${key}_BYD`, { lng: i18next.language }) !== i18next.t(`${key}_BYD`, { lng: 'en' })) &&
      // check if the beyond specific value is not equal to the normal (now) value. A falsy return value verifies that
      // while the beyond specific value is defined, it is the same as the now value and therefore redundant
      i18next.t(key) !== i18next.t(`${key}_BYD`)
        ? `${key}_BYD`
        : key

    // If the key does not exist in our translations we just return the provided text.
    //
    // These are most likely values from the API that can be either an existing translation
    // or an individual text from the merchant which does not get a translation.
    // An example for this is the stock-level availabilityText.
    //
    // Setting `count` is needed so that pluralized locales are found with the i18next
    // key lookup (they have a plural key suffix like "_one" or "_other").
    // See: https://www.i18next.com/translation-function/plurals
    if (!i18next?.exists(translationKey, { count: 1 })) return translationKey

    if (!shouldUsePhraseAppInContextEditor()) return i18next.t(translationKey, options)

    const namespace = namespaceRE.test(key) ? key.split(':')[0] : i18next.options.defaultNS

    // Keys in "themes" namespace are not translated with PhraseApp.
    if (namespace === 'themes') return i18next.t(key, options)

    const { prefix, suffix } = phraseAppConfig
    const keyWithoutNamespace = key.replace(namespaceRE, '')

    return `${prefix}phrase_${keyWithoutNamespace}${suffix}`
  }
}

/** Translate with components as placeholder values */
export function withComponents(t: TranslateProps['t']) {
  return (translationKey: ParseKeys, components: Record<string, ReactNode>) => {
    // Make sure the translation is not interpolated since we need the placeholders for component injection
    const nonInterpolatedTranslation: string = t(translationKey, { interpolation: { suffix: '__', prefix: '__' } })
    // Get everything in between the placeholders for interspersal
    const intersperseTranslations = nonInterpolatedTranslation.split(/{{\w+}}/g)
    // Get the placeholders
    const placeholders: string[] = nonInterpolatedTranslation.match(/{{(\w)+}}/g) || []

    return placeholders.reduce<ReactNode>(
      (translationPart, placeholder, index) => (
        <>
          {translationPart || null}
          {components[placeholder.match(/\w+/)?.[0] || '']}
          {intersperseTranslations[index + 1] || null}
        </>
      ),
      intersperseTranslations[0],
    )
  }
}

/** Creates "translate" higher-order component to use with i18next and Phrase. */
export default function translate(scope?: string) {
  return function withTranslation<P>(WrappedComponent: ComponentType<P>) {
    class Translate extends React.Component<Omit<P, keyof TranslateProps>> {
      static displayName = WrappedComponent.displayName || 'Component'

      componentDidMount() {
        if (shouldUsePhraseAppInContextEditor()) initializePhraseAppEditor()
      }

      render() {
        // Accessing the store here directly because using connect (from redux) leads to issues with rerendering
        // These issues occur because we are using the legacy context api: https://reactjs.org/docs/legacy-context.html#updating-context
        return (
          <i18nContext.Consumer>
            {(i18n: i18n) => (
              <ReactReduxContext.Consumer>
                {({ store }) => {
                  const isBeyond = Boolean(store.getState().getIn(['shop', 'beyond']))
                  const unscopedT = createT(i18n, isBeyond)
                  const t = scope
                    ? // allow scoping by passing scope parameter
                      (key: ParseKeys, opts?: TOptions) =>
                        key.startsWith(':')
                          ? // allow breaking out of the scope by using a colon prefix
                            unscopedT(key.substring(1), opts)
                          : unscopedT(`${scope}.${key}`, opts)
                    : unscopedT
                  return <WrappedComponent {...(this.props as P)} t={t} />
                }}
              </ReactReduxContext.Consumer>
            )}
          </i18nContext.Consumer>
        )
      }
    }

    return hoistStatics(Translate, WrappedComponent)
  }
}
