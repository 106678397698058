import { useEffect, useRef, useState } from 'react'

type ImageCanvasProps = Readonly<{
  src: string
  aspectRatio?: number
  zoomLevel: number
  onUpdate?: (canvas: HTMLCanvasElement, sourceImage: HTMLImageElement) => void
}>

/** A component that displays an image on a canvas, maintaining the aspect ratio. */
export function ImageCanvas({ src, aspectRatio, zoomLevel, onUpdate }: ImageCanvasProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [sourceImage, setSourceImage] = useState<HTMLImageElement | null>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d')

    if (canvas && context && sourceImage) {
      drawImage(context, sourceImage, zoomLevel)
      onUpdate?.(canvas, sourceImage)
    }
  }, [sourceImage, onUpdate, zoomLevel])

  useEffect(() => {
    const container = containerRef.current
    const canvas = canvasRef.current

    if (canvas && container) {
      const image = new Image()
      image.src = src
      // Once the image is loaded, set the canvas size and the image.
      // The canvas size is set to at least the width of the container,
      // maintaining the aspect ratio.
      image.onload = () => {
        canvas.width = image.width > container.clientWidth ? image.width : container.clientWidth
        canvas.height = canvas.width / (aspectRatio || image.width / image.height)
        setSourceImage(image)
      }
    }
  }, [src, aspectRatio])

  return (
    <div ref={containerRef} style={{ aspectRatio }}>
      <canvas
        ref={canvasRef}
        style={{
          display: 'block',
          maxWidth: '100%',
        }}
      />
    </div>
  )
}

/**
 * Draws the given image on the canvas of the given canvas rendering context.
 *
 * The image will be sized to cover the entire canvas while maintaining its
 * aspect ratio, clipping the image if necessary. Like CSS `object-fit: cover`.
 */
function drawImage(context: CanvasRenderingContext2D, image: HTMLImageElement, zoomLevel = 1) {
  const canvas = context.canvas

  // Always center the image.
  // In the future, this will be updated to allow custom positioning.
  const offsetX = 0.5
  const offsetY = 0.5

  // Calculate the ratio of the canvas to the image, and use the smaller ratio
  // to calculate the proportional width and height for the image.
  const ratio = Math.min(canvas.width / image.width, canvas.height / image.height)
  let newImageWidth = image.width * ratio
  let newImageHeight = image.height * ratio

  // Calculate the aspect ratio to be used for the image source rectangle to be
  // drawn onto canvas. It is calculated based on the new proportional width
  // and height for the image, and the width and height of the canvas.
  let aspectRatio = 1
  if (newImageWidth < canvas.width) {
    aspectRatio = canvas.width / newImageWidth
  }
  // Take floating point rounding errors into account by using an epsilon error
  // margin, so that the aspect ratio is not changed if the image is already
  // correctly scaled to the canvas width. Consider the following example:
  // canvas = { width: 854, height: 854 }, image = { width: 965, height: 1600 }
  // In Chrome, this results in the following values:
  // newImageWidth = 515.0687499999999, newImageHeight = 853.9999999999999
  if (Math.abs(aspectRatio - 1) < 1e-14 && newImageHeight < canvas.height) {
    aspectRatio = canvas.height / newImageHeight
  }

  // Calculate the final new width and height for the image based on the aspect
  // ratio and the zoom level.
  newImageWidth *= aspectRatio * zoomLevel
  newImageHeight *= aspectRatio * zoomLevel

  // Calculate the source rectangle, representing the portion of the image that
  // will be drawn onto canvas. The source rectangle is calculated based on the
  // calculated new width and height for the image, the width and height of the
  // canvas, and the offset values.
  let sourceWidth = image.width / (newImageWidth / canvas.width)
  let sourceHeight = image.height / (newImageHeight / canvas.height)
  let sx = (image.width - sourceWidth) * offsetX
  let sy = (image.height - sourceHeight) * offsetY

  // Clamp the source rectangle values to the image boundaries.
  // This is necessary because the source rectangle values can be negative or
  // larger than the image width or height, which would cause the image to be
  // drawn incorrectly.
  if (sourceWidth > image.width) sourceWidth = image.width
  if (sourceHeight > image.height) sourceHeight = image.height
  if (sx < 0) sx = 0
  if (sy < 0) sy = 0

  // Clear the canvas and draw the image onto it using the calculated values.
  context.clearRect(0, 0, canvas.width, canvas.height)
  context.drawImage(image, sx, sy, sourceWidth, sourceHeight, 0, 0, canvas.width, canvas.height)
}
