import type { ReactElement } from 'react'

import Breadcrumb from '../epages.base/Breadcrumb'
import CountrySelection from '../../CountrySelection'
import CustomCss from '../../templateComponents/CustomCss'
import DeliveryCountrySelection from '../../templateComponents/DeliveryCountrySelection'
import HeaderAnnouncementBar from '../epages.base/HeaderAnnouncementBar'
import HeaderUsp from '../epages.base/HeaderUsp'
import LanguageSwitch from '../../templateComponents/LanguageSwitch'
import LegalFooterNavigation from '../epages.base/LegalFooterNavigation'
import Logistics from '../epages.base/Logistics'
import NewsletterForm from '../../templateComponents/NewsletterForm'
import Payments from '../epages.base/Payments'
import SocialMedia from '../epages.base/SocialMedia'
import ThemeHeader from '../epages.base/ThemeHeader'

export default function Layout(props: LayoutThemeComponentProps): ReactElement {
  const {
    themeSettings,
    isTaxationByDestinationCountryEnabled,
    isEditor,
    content,
    shop,
    breadcrumb,
    legalPages,
    footerPages,
    t,
  } = props

  return (
    <div className="container">
      <CustomCss src={(themeSettings.customStyle || {}).customCss} />

      {!isEditor && isTaxationByDestinationCountryEnabled ? (
        <section className="section delivery-country-selection">
          <DeliveryCountrySelection t={t} />
        </section>
      ) : null}

      {shop.userSettings.headerAnnouncement?.active ? (
        <HeaderAnnouncementBar headerAnnouncementHtml={shop.userSettings.headerAnnouncement.announcementHtml} />
      ) : null}

      {shop.userSettings.headerUsps.length ? <HeaderUsp headerUsps={shop.userSettings.headerUsps} /> : null}

      <div className="page-canvas">
        <ThemeHeader {...props} />

        <main className="main">
          <Breadcrumb breadcrumb={breadcrumb} isHidden />
          {content}
        </main>

        <footer className="site-footer">
          <div className="footer">
            <NewsletterForm className="newsletter-form">
              <label className="newsletter-form-label" htmlFor="newsletter-form-field">
                {t('components.newsletterSubscriptionComponent.title')}
              </label>
              <div className="newsletter-form-register">
                <input
                  className="newsletter-form-field"
                  id="newsletter-form-field"
                  name="email"
                  type="email"
                  required
                  placeholder={t('components.newsletterSubscriptionComponent.emailAddressInputField.placeholder')}
                />
                <button className="newsletter-form-button" type="submit">
                  <span>{t('components.newsletterSubscriptionComponent.submitButton.label')}</span>
                </button>
              </div>
            </NewsletterForm>
            <SocialMedia socialNetworks={shop.userSettings.socialNetworks} />
            <LegalFooterNavigation {...{ legalPages, footerPages }} />

            <Payments paymentLogos={shop.userSettings.paymentLogos} t={t} />
            <Logistics shippingLogos={shop.userSettings.shippingLogos} />

            <div className="country-selection">
              <CountrySelection>
                {(shopCountries, setSellingCountry, shopSellingCountry) => (
                  <div className="delivery-country">
                    <label htmlFor="country-selection">
                      {t('components.bottomBar.footerSettings.countrySelection.label')}
                    </label>
                    <div className="footer-switch">
                      <select
                        id="country-selection"
                        value={shopSellingCountry || ''}
                        onChange={({ target }) => setSellingCountry(Number(target.value))}
                      >
                        {shopCountries.map(({ countryId, name }) => (
                          <option key={countryId} value={countryId}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </CountrySelection>
              <LanguageSwitch>
                {(locales, switchLanguage) => (
                  <div className="multi-language">
                    <label htmlFor="language-switch">
                      {t('components.bottomBar.footerSettings.switchLanguage.label')}
                    </label>
                    <div className="footer-switch">
                      <select
                        id="language-switch"
                        defaultValue={locales.find((l) => l.isActive).identifier}
                        onChange={({ target }) => switchLanguage(target.value)}
                      >
                        {locales.map((locale) => (
                          <option key={locale.identifier} value={locale.identifier}>
                            {t(`enumerations.languages.${locale.identifier.substr(0, 2)}`)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </LanguageSwitch>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
