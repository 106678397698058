import type { ReactElement } from 'react'

import { imageUrl } from '../../ThemeView'
import LazyImage from '../../LazyImage'

type Props = {
  shippingLogos?: {
    id: string
    title?: string
    path?: string
    filename?: string
  }[]
}

export default function Logistics({ shippingLogos = [] }: Props): ReactElement | null {
  if (!shippingLogos.length) return null

  return (
    <ul className="logistics">
      {shippingLogos.map((logo) => (
        <li className="logistics-item" key={logo.id}>
          <div className="logistics-item-box">
            <LazyImage
              className="logistics-item-box-logo"
              src={logo.path ? imageUrl(logo.path, {}) : `/themes/epages.base/assets/logistics/${logo.filename}`}
              srcSet={
                logo.path
                  ? `${imageUrl(logo.path, { height: 22 })} 1x, ${imageUrl(logo.path, { height: 44 })} 2x`
                  : undefined
              }
              alt={logo.title}
              title={logo.title}
            />
          </div>
        </li>
      ))}
    </ul>
  )
}
