import type { ReactElement } from 'react'
import { noop } from 'lodash'
import getSymbolFromCurrency from 'currency-symbol-map'

type Filter = {
  label: string
  handleClick: () => void
}

type Props = {
  facets?: Core.Facets
  queryString?: string
  onQueryFilterChange?: () => void
  onSelectionFilterChange?: (facetId: string, value: Core.FacetSelectionValue) => void
  onRangeFilterChange?: (
    facetId: string,
    range: {
      min: number
      max: number
    },
  ) => void
}

export default function SearchFilters({
  facets,
  queryString,
  onQueryFilterChange = noop,
  onRangeFilterChange = noop,
  onSelectionFilterChange = noop,
}: Props): ReactElement | null {
  const filters: Filter[] = []

  if (queryString && typeof queryString === 'string' && queryString !== '*') {
    filters.push({
      label: queryString,
      handleClick: () => {
        onQueryFilterChange()
      },
    })
  }

  if (facets) {
    Object.values(facets).forEach((facet) => {
      if (facet.type === 'selection') {
        Object.values(facet.values).forEach((value) => {
          if (value.selected)
            filters.push({
              label: value.name,
              handleClick: () => {
                onSelectionFilterChange(facet.id, value)
              },
            })
        })
      }
      if (facet.type === 'range' && facet.values) {
        const values = facet.values
        const selection = values.selection
        const unit = getSymbolFromCurrency(values.unit)
        const label =
          selection.min !== selection.max
            ? `${selection.min} ${unit} - ${selection.max} ${unit}`
            : `${selection.min} ${unit}`
        if (values.selected)
          filters.push({
            label,
            handleClick: () => {
              onRangeFilterChange(facet.id, values.range)
            },
          })
      }
    })
  }

  return filters.length > 0 ? (
    <div className="search-filter">
      <scroll-shadow class="search-filter-tags-wrapper">
        <div className="search-filter-tags">
          {filters.map(({ label, handleClick }) => (
            <button className="search-filter-tag" onClick={handleClick} key={label}>
              {label}
            </button>
          ))}
        </div>
      </scroll-shadow>
    </div>
  ) : null
}
