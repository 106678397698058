import { List, Map } from 'immutable'

const emptyState = new Map({
  location: undefined, // new Map()
  shop: undefined, // new Map()
  cart: undefined, // new Map()
  checkout: new Map(),
  customer: new Map(),
  themes: undefined, // new List()
  themeVariablesCss: undefined, // new List()
  orders: new Map(),
  navigation: undefined, // new Map()
  breadcrumb: undefined, // new List()
  pages: new Map(),
  legalPages: undefined, // new List()
  footerPages: new List(),
  legalPagesContents: new Map(),
  categories: new Map(),
  categoriesList: new Map(),
  categorySlugsToGUIDs: new Map(),
  categoryProductData: new Map(),
  channels: new Map(),
  searchData: new Map(),
  crossSellingProductData: new Map(),
  products: new Map(),
  view: new Map({
    meta: [],
    link: [],
    busy: false,
    interfaceLanguage: undefined,
    error: undefined, // new Map
    notifications: new List(),
    editorMode: false,
    editorSidebar: new Map({
      activeModule: 'none',
    }),
    mboMenu: [],
    mboMenuMode: false,
    isThemePreview: undefined,
    hashedThemeAssets: new Map(),
  }),
})

export default emptyState
