import type { HTMLAttributes, ReactElement } from 'react'
import React from 'react'
import ReactDOM from 'react-dom'

import isMounted from '../../utils/hooks/useIsMounted'

export default function Portal(props: HTMLAttributes<HTMLDivElement>): ReactElement | null {
  if (!isMounted()) return null

  const themeBody = document.querySelector('.body')
  if (!themeBody) return null

  return ReactDOM.createPortal(<div {...props} />, themeBody)
}
