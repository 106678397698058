import type { ReactElement } from 'react'
import { defaultsDeep, isPlainObject, kebabCase, merge, set } from 'lodash'
import { useContext } from 'react'
import { useSelector } from 'react-redux'

import * as BaseComponents from './themeComponents/epages.base'
import * as DimensionComponents from './themeComponents/epages.dimension'
import * as EditorialComponents from './themeComponents/epages.editorial'
import * as EssenceComponents from './themeComponents/epages.essence'
import * as HorizonComponents from './themeComponents/epages.horizon'
import * as LimelightComponents from './themeComponents/epages.limelight'
import * as NeutralComponents from './themeComponents/epages.neutral'
import * as StructureComponents from './themeComponents/epages.structure'
import * as TestComponents from './themeComponents/epages.test-theme'
import * as UptownComponents from './themeComponents/epages.uptown'
import * as VisionComponents from './themeComponents/epages.vision'
import { createT } from '../utils/translate'
import { i18nInstancesContext } from './I18nProvider'
import { themeGroupDelimiter } from '../../utils/themes'
import buildURL from '../../utils/buildURL'

const themes = {}
themes['epages.neutral'] = { ...BaseComponents, ...NeutralComponents }
themes['epages.dimension'] = { ...themes['epages.neutral'], ...DimensionComponents }
themes['epages.editorial'] = { ...themes['epages.neutral'], ...EditorialComponents }
themes['epages.essence'] = { ...BaseComponents, ...EssenceComponents }
themes['epages.structure'] = { ...themes['epages.essence'], ...StructureComponents }
themes['epages.test-theme'] = { ...BaseComponents, ...TestComponents }
themes['epages.horizon'] = { ...themes['epages.test-theme'], ...HorizonComponents }
themes['epages.uptown'] = { ...themes['epages.neutral'], ...UptownComponents }
themes['epages.vision'] = { ...BaseComponents, ...VisionComponents }
themes['epages.limelight'] = { ...themes['epages.essence'], ...LimelightComponents }

export function imageUrl(url: string, width: Record<string, unknown> | number, height?: number): string {
  return isPlainObject(width) ? buildURL(url, width as object) : buildURL(url, { width, height })
}

type ThemeViewProps = {
  shop: Core.Shop & { theme: any; themeSettings: any }
  view:
    | 'Account'
    | 'Error'
    | 'Checkout'
    | 'ForgotPassword'
    | 'Legal'
    | 'Order'
    | 'Page'
    | 'ResetPassword'
    | 'Cart'
    | 'Category'
    | 'FacetedSearch'
    | 'Home'
    | 'Layout'
    | 'Product'
    | 'Search'
}

export default function ThemeView({ view, ...props }: ThemeViewProps): ReactElement {
  const themeName = useSelector<State, string>((state) => state.getIn(['shop', 'theme', 'name']))
  const [themeNamespace] = themeName.split(themeGroupDelimiter)

  const isBeyond = useSelector<State, boolean>((state) => Boolean(state.getIn(['shop', 'beyond'])))
  const hashedThemeAssets = useSelector<State, ImmutableMap>((state) => state.getIn(['view', 'hashedThemeAssets']))
  const defaultThemeSettings = useSelector<State, ImmutableMap>((state) => state.getIn(['defaultThemeSettings']))
  const { shopI18n } = useContext(i18nInstancesContext)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const translate = createT(shopI18n!, isBeyond)

  let themeComponentProps: any

  if (props && props.shop) {
    // set a boolean on social networks as indicator if at least one social network is active
    themeComponentProps = merge({}, props, {
      shop: {
        userSettings: {
          socialNetworks: {
            show: Object.keys(props.shop.userSettings.socialNetworks).some(
              (key) => props.shop.userSettings.socialNetworks[key] === true,
            ),
          },
        },
      },
    })
    // provide one theme settings object, merged with theme defaults
    themeComponentProps.themeSettings = defaultsDeep(props.shop.themeSettings, defaultThemeSettings.toJS())
    // only pass activated header USPs to the theme
    set(
      themeComponentProps,
      'shop.userSettings.headerUsps',
      (props.shop.userSettings.headerUsps || []).filter((usp) => usp.active),
    )
    // only pass activated payment logos to the theme
    set(
      themeComponentProps,
      'shop.userSettings.paymentLogos',
      (props.shop.userSettings.paymentLogos || [])
        .filter((logo) => logo.active)
        .map((logo) => ({
          ...logo,
          filename: hashedThemeAssets.getIn(['payments', `${kebabCase(logo.id)}.svg`]),
        })),
    )
    // only pass activated shipping logos to the theme
    set(
      themeComponentProps,
      'shop.userSettings.shippingLogos',
      (props.shop.userSettings.shippingLogos || [])
        .filter((logo) => logo.active)
        .map((logo) => ({
          ...logo,
          filename: hashedThemeAssets.getIn(['logistics', logo.filename]),
        })),
    )
  }

  const Component = themes[themeNamespace][view]

  return <Component t={translate} {...themeComponentProps} />
}
