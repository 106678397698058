/**
 * Checks if a given file truly has an image MIME type that we support for our image plugins.
 * Uses the file's byte pattern to check the MIME type.
 * Background: Sometimes it is not sufficient to check the file.type as it is only assumed based on the file's extension
 * (which could be changed by the user), and not on the file's bytestream, which would show the actual true MIME type.
 * See info box on https://developer.mozilla.org/en-US/docs/Web/API/File/type for more information.
 *
 * @param {File} file The file to be checked
 * @returns {Promise<boolean>} The answer whether a file has a supported MIME type or not
 */

// File signatures for JPG, JPEG, PNG and GIF as per https://www.garykessler.net/library/file_sigs.html
const SUPPORTED_MIME_TYPES = ['89504e47', '47494638', 'ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe8']

export default async function checkSupportedImageMediaType(file: File): Promise<boolean> {
  const fileHeader = await getFileHeader(file)

  return SUPPORTED_MIME_TYPES.includes(fileHeader)
}

// https://stackoverflow.com/a/71320737
async function getFileHeader(file: File): Promise<string> {
  return new Promise((resolve) => {
    const headerBytes = file.slice(0, 4) // Read the first 4 bytes of the file
    const fileReader = new FileReader()
    fileReader.onloadend = (e: ProgressEvent<FileReader>) => {
      const arr = new Uint8Array(e?.target?.result as ArrayBufferLike).subarray(0, 4)
      let header = ''
      for (const entry of arr) {
        header += entry.toString(16)
      }
      resolve(header)
    }
    fileReader.readAsArrayBuffer(headerBytes)
  })
}
