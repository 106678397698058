import type { ReactElement } from 'react'

import Link from '../../templateComponents/Link'

export default function Layout({
  shop,
  currentView,
  navigation,
  footerPages,
  legalPages,
  breadcrumb,
  content,
}: LayoutThemeComponentProps & {
  currentView: string
  navigation: {
    title: string
    href: string
  }[]
}): ReactElement {
  return (
    <div className={`template-layout view-${currentView.toLowerCase()}`}>
      <h1 style={{ display: 'flex', flexDirection: 'column', padding: '1em' }}>
        🚚️ this site has moved
        <a href="http://fatty.localhost:7000">to Fatty (ePagesNow)</a>
        <a href="http://skinny.localhost:7000">to Skinny (In-Store aka Beyond)</a>
        <details>
          <summary>Doesn&apos;t work?</summary>
          Try this (in your terminal):
          <pre>echo &apos;127.0.0.1 fatty.localhost skinny.localhost&apos; | sudo tee -a /etc/hosts &gt; /dev/null</pre>
        </details>
      </h1>

      <div>{shop.slug}</div>
      <nav>
        <ul>
          {navigation.map((page) => (
            <li key={page.title}>
              <Link to={page.href}>{page.title}</Link>
            </li>
          ))}
        </ul>
      </nav>
      {breadcrumb.length > 1 && (
        <ul className="breadcrumb">
          {breadcrumb.map((breadcrumb) => (
            <li key={breadcrumb.title}>
              <Link to={breadcrumb.href}>{breadcrumb.title}</Link>
            </li>
          ))}
        </ul>
      )}
      <main>{content}</main>
      <footer>
        <ul>
          {legalPages.map((legalPage) => (
            <li key={legalPage.id}>
              <Link to={legalPage.href}>{legalPage.title}</Link>
            </li>
          ))}
        </ul>
        <ul className="footer-pages">
          {footerPages.map((footerPage) => (
            <li key={footerPage.id}>
              <Link to={footerPage.href}>{footerPage.title}</Link>
            </li>
          ))}
        </ul>
      </footer>
    </div>
  )
}
