import { camelCase, noop } from 'lodash'
import { fromJS } from 'immutable'
import striptags from 'striptags'

import type { Props } from './MultiColumnBlock'
import { emptyHeadline } from '../../../editorSidebarBlockTemplates'
import Element from '../Element'

export const ImageWithText = makePluginWithText('image')
export const VideoWithText = makePluginWithText('video')
export const IconWithText = makePluginWithText('icon')

function makePluginWithText(type: string) {
  function PluginWithText({ config, editorView, data, onDataChange }: Props) {
    return (
      <>
        {editorView && (
          <div className="dali-grid-headline">
            <Element
              config={config}
              editorView={editorView}
              element={data.get('headline') || fromJS(emptyHeadline)}
              onElementChange={(element: Core.Plugin) => onDataChange(data.set('headline', element))}
            />
          </div>
        )}
        {!editorView && striptags(data.getIn(['headline', 'data', 'content'])) && (
          <div className="dali-grid-headline">
            <Element config={config} editorView={false} element={data.get('headline')} onElementChange={noop} />
          </div>
        )}
        <div className="dali-grid-row">
          {data.get('columns').map((column, index) => (
            <div key={index} className="dali-grid-column">
              <div className="dali-grid-element">
                <Element
                  config={config}
                  editorView={editorView}
                  element={column.get(type)}
                  onElementChange={(element: Core.Plugin) =>
                    onDataChange(data.setIn(['columns', index, type], element))
                  }
                />
              </div>
              <div className="dali-grid-element">
                <Element
                  config={config}
                  editorView={editorView}
                  element={column.get('text')}
                  onElementChange={(element: Core.Plugin) =>
                    onDataChange(data.setIn(['columns', index, 'text'], element))
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }

  PluginWithText.getElements = (data: Core.Block['data']) =>
    [data.headline, ...data.columns.flatMap((col: Record<string, Core.Plugin>) => [col[type], col.text])].filter(
      Boolean,
    )

  PluginWithText.displayName = camelCase(type) + 'WithText'

  return PluginWithText
}
