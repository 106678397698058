import Immutable from 'immutable'

import * as actions from '../actions'

export type FailureAction = {
  type: string
  options?: {
    [key: string]: any
  }
  errorMessage: string
  requestId?: string
  serverRequestId?: string
  statusMessage?: string
}

export default function errors(state: State, action: FailureAction): State {
  switch (action.type) {
    case 'SWITCH_THEME_FAILURE': // importing the constant would break code splitting
    case actions.GET_THEMES_FAILURE:
    case actions.GET_SEARCH_SUGGESTIONS_FAILURE:
    case actions.GET_THEME_SETTINGS_SCHEMA_FAILURE:
    case actions.GET_THEME_SETTINGS_FAILURE:
    case actions.GET_SCRIPT_TAGS_FAILURE:
    case actions.GET_RESELLER_NAME_FAILURE:
    case actions.GET_SOCIAL_SHARING_ENABLED_FAILURE:
    case actions.GET_TAX_FAILURE:
    case actions.SET_THEME_SETTINGS_FAILURE:
    case actions.SET_THEME_FAILURE:
    case actions.GET_DEFAULT_THEME_SETTINGS_FAILURE:
    case actions.GET_THEME_META_FAILURE:
    case actions.SET_USER_SETTINGS_FAILURE:
    case actions.SET_FAVICON_FAILURE:
    case actions.SET_SHOP_LOGO_FAILURE:
    case actions.LOAD_SHOP_EMAIL_FAILURE:
    case actions.LOAD_NAVIGATION_FAILURE:
    case actions.UPDATE_FOOTER_NAVIGATION_FAILURE:
    case actions.UPDATE_NAVIGATION_FAILURE:
    case actions.LOAD_LEGAL_PAGES_FAILURE:
    case actions.LOAD_FOOTER_PAGES_FAILURE:
    case actions.CREATE_PAGE_FAILURE:
    case actions.DUPLICATE_PAGE_FAILURE:
    case actions.DELETE_PAGE_FAILURE:
    case actions.LOAD_LEGAL_PAGE_FAILURE:
    case actions.UPDATE_PAGE_CONTENT_FAILURE:
    case actions.UPDATE_LEGAL_PAGE_CONTENT_FAILURE:
    case actions.UPDATE_PAGE_SETTINGS_FAILURE:
    case actions.UPDATE_CATEGORY_SETTINGS_FAILURE:
    case actions.UPDATE_LEGAL_SETTINGS_FAILURE:
    case actions.UPDATE_LINE_ITEM_QUANTITY_FAILURE:
    case actions.REMOVE_LINE_ITEM_FAILURE:
    case actions.UPDATE_CATEGORY_CONTENT_FAILURE:
    case actions.LOAD_PRODUCTS_BY_IDS_FAILURE:
    case actions.LOAD_CATEGORY_FAILURE:
    case actions.LIST_CATEGORIES_FAILURE:
    case actions.LOAD_PRODUCT_FAILURE:
    case actions.LOAD_ORDER_FAILURE:
    case actions.LOAD_CART_FAILURE:
    case actions.ADD_TO_CART_FAILURE:
    case actions.LOAD_CART_SERVICEABLE_COUNTRIES_FAILURE:
    case actions.LOAD_ALL_COUNTRIES_FAILURE:
    case actions.LOAD_CART_APPLICABLE_SHIPPING_METHODS_FAILURE:
    case actions.LOAD_CART_APPLICABLE_PAYMENT_METHODS_FAILURE:
    case actions.SET_CART_BILLING_ADDRESS_FAILURE:
    case actions.SET_CART_SHIPPING_ADDRESS_FAILURE:
    case actions.ASSIGN_CUSTOMER_TO_CART_FAILURE:
    case actions.LOGIN_CUSTOMER_FAILURE:
    case actions.REFRESH_CUSTOMER_LOGIN_FAILURE:
    case actions.LOGOUT_CUSTOMER_FAILURE:
    case actions.SIGNUP_CUSTOMER_FAILURE:
    case actions.REQUEST_PASSWORD_RESET_FAILURE:
    case actions.RESET_PASSWORD_FAILURE:
    case actions.REQUEST_EMAIL_CHANGE_FAILURE:
    case actions.CONFIRM_EMAIL_CHANGE_FAILURE:
    case actions.SET_CART_SHIPPING_METHOD_FAILURE:
    case actions.SET_CART_PAYMENT_METHOD_FAILURE:
    case actions.SET_CART_CREATE_PAYMENT_FAILURE:
    case actions.SET_CART_CREATE_PAYMENT_AND_ORDER_FAILURE:
    case actions.UNSET_CART_PICKUP_OPTION_FAILURE:
    case actions.ORDER_CART_FAILURE:
    case actions.REDEEM_COUPON_FAILURE:
    case actions.REMOVE_COUPON_FAILURE:
    case actions.SWITCH_LANGUAGE_FAILURE:
    case actions.LOAD_PRODUCTS_FOR_CROSSSELLING_FAILURE:
    case actions.SUBMIT_CONTACT_FORM_FAILURE:
    case actions.LOAD_SHOP_COUNTRIES_FAILURE:
    case actions.LIST_ORDERS_FAILURE:
    case actions.DELETE_CUSTOMER_FAILURE:
    case actions.GET_CUSTOMER_DETAILS_FAILURE:
    case actions.UPDATE_CUSTOMER_BILLING_ADDRESS_FAILURE:
    case actions.UPDATE_CUSTOMER_SHIPPING_ADDRESS_FAILURE:
    case actions.CHANGE_PASSWORD_FAILURE:
      if (/coupon failed/.test(action.errorMessage)) {
        const regExpReason = /Reason: (?<id>.*)/i
        const regExpMinOrder = /Reason: (?<id>.*)\. MinOrderValue: (?<minOrderValue>.*)/i
        const match = regExpMinOrder.exec(action.errorMessage) || regExpReason.exec(action.errorMessage)

        const { id, minOrderValue } = match?.groups || {}
        const params = minOrderValue ? { value: minOrderValue } : {}

        return state.set('cart', state.get('cart').set('couponError', { id, params }))
      }
      // a update_line_item_quantity error when stocklevel is reached
      // error message is handled by the cart, no need for toast message
      // only do this when we're actually on the cart page (= no buybox)
      if (
        (state.getIn(['location', 'pathname'])?.endsWith('/cart') ||
          state.getIn(['location', 'pathname'])?.endsWith('/checkout/confirmation')) &&
        /Low StockLevel/.test(action.errorMessage)
      ) {
        // scroll to top if there is a error message and we are in the browser
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0)
        }

        state = state
          .set('cart', state.get('cart').set('quantityMismatch', true))
          .setIn(['view', 'cart', 'quantities'], Immutable.Map())

        state = state.getIn(['cart', 'checkoutState'])
          ? state.setIn(['cart', 'checkoutState', 'quantityValidToOrder'], false)
          : state
              .setIn(['cart', 'checkoutState'], Immutable.Map())
              .setIn(['cart', 'checkoutState', 'quantityValidToOrder'], false)

        return state
      }

      if (action.type === actions.CONFIRM_EMAIL_CHANGE_FAILURE && action.statusMessage === 'Bad Request') {
        // trigger dedicated error notification
        action.statusMessage = 'emailConfirmationFailed'
      }

      if (action.options && action.options.showErrorNotification) {
        const errorId = 'req-error-' + action.requestId
        return state.updateIn(['view', 'notifications'], (ns) =>
          ns.push(
            Immutable.fromJS({
              id: errorId,
              level: 'error',
              serverRequestId: action.serverRequestId || null,
              // prefer the status message because it is translatable
              // fall back to error message to at least show something
              message: action.statusMessage || action.errorMessage,
            }),
          ),
        )
      }
      return state
    default:
      return state
  }
}
