import React from 'react'

import type { AvailableSortings } from '../../../../../utils/pageAndSort'
import { createSortings } from '../../../../../utils/pageAndSort'

type Props = {
  value: string
  options: Partial<AvailableSortings>
  onChange: (value: string) => void
} & TranslateProps

export default function Sorting({ value, options, t, onChange }: Props): React.ReactElement {
  const sortings = createSortings(value, options)
  return (
    <label className="sorting">
      <span>{t('components.productListComponent.sortingDropdown.label')}</span>
      <div className="sorting-select">
        <select value={value} onChange={(e) => onChange(e.target.value)}>
          {sortings.map((sorting) => (
            <option value={sorting.sorting} key={sorting.label}>
              {t(`components.productListComponent.sortingDropdown.options.${sorting.label}`)}
            </option>
          ))}
        </select>
      </div>
    </label>
  )
}
