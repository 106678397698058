import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import FocusTrap from 'focus-trap-react'
import cc from 'classcat'
import cookies from 'js-cookie' // used on the client only

import { declineCookieConsent } from '../store/actions'
import Portal from './templateComponents/Portal'
import translate from '../utils/translate'

type CookieConsentProps = {
  inline?: boolean
  isEditor: boolean
} & TranslateProps

export function CookieConsentRaw({ t, inline = false, isEditor }: CookieConsentProps) {
  const [isOnSmallScreen, setIsOnSmallScreen] = useState(false)
  const cookieNoticeSettings = useSelector<State, any>((state) => state.getIn(['shop', 'userSettings', 'cookieNotice']))
  const cookieConsent = useSelector<State, boolean>((state) => state.get('cookieConsent'))
  const showInline = inline && (cookieConsent !== null || isEditor)
  const showInPortal = !inline && !isEditor && cookieConsent === null && typeof window !== 'undefined'
  const [isBodyClosed, setIsBodyClosed] = useState(false)
  const [hasAccepted, setHasAccepted] = useState(cookieConsent)
  const dispatch = useDispatch()

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 767px)')
    const listener = () => setIsOnSmallScreen(mediaQuery.matches)
    mediaQuery.addListener(listener)
    listener()
    return () => mediaQuery.removeListener(listener)
  }, [])

  if (!cookieNoticeSettings.get('active')) return null

  const handleDecline = () => {
    if (cookieConsent === false) return

    // expires defines expiration of the cookie in days.
    cookies.set('epCookieConsent', '0', { expires: 365 * 2, sameSite: 'lax' })

    if (inline) {
      setHasAccepted(false)
      window.location.reload()
    } else {
      dispatch(declineCookieConsent())
    }
  }
  const handleAccept = () => {
    if (cookieConsent === true) return

    // expires defines expiration of the cookie in days.
    cookies.set('epCookieConsent', '1', { expires: 365 * 2, sameSite: 'lax' })

    if (inline) setHasAccepted(true)
    window.location.reload()
  }

  const component = (
    <div
      className={cc(['lightbox cc-dialog', showInline && 'lightbox-centered cc-dialog-inline'])}
      style={isOnSmallScreen ? { marginBottom: 0 } : {}}
      role="dialog"
      aria-modal="true"
      aria-labelledby="cookieconsent:title"
      aria-describedby="cookieconsent:desc"
    >
      {/* Unsetting transform so the button is positioned correctly in the Beyond preview. */}
      <style>{`.body { transform: unset; }`}</style>
      <div className="cc-dialog-header">
        <h1 id="cookieconsent:title" className="cc-dialog-title">
          {cookieNoticeSettings.get('title') || ''}
        </h1>
        {isOnSmallScreen && (
          <span
            className={cc(['cc-dialog-toggle', { open: !isBodyClosed, close: isBodyClosed }])}
            onClick={() => setIsBodyClosed(!isBodyClosed)}
            data-testid="consentMinimizer"
            role="button"
            tabIndex={0}
            aria-hidden="true"
          />
        )}
      </div>
      <scroll-shadow class="cc-dialog-content-wrapper">
        <div
          id="cookieconsent:desc"
          className={cc(['modal-content cc-dialog-content', { close: isBodyClosed && isOnSmallScreen }])}
          dangerouslySetInnerHTML={{ __html: cookieNoticeSettings.get('gdprMessageHtml') || '' }}
        />
      </scroll-shadow>
      <div className="cc-dialog-buttons">
        <button
          className={cc(['cc-dialog-button-decline', inline && !hasAccepted && 'cc-dialog-button-active'])}
          type="button"
          onClick={handleDecline}
        >
          {t('components.cookieConsentComponent.declineButton.label')}
        </button>
        <button
          className={cc(['cc-dialog-button-accept', inline && hasAccepted && 'cc-dialog-button-active'])}
          type="button"
          onClick={handleAccept}
        >
          {t('components.cookieConsentComponent.acceptButton.label')}
        </button>
      </div>
    </div>
  )

  if (showInline) {
    return component
  } else if (showInPortal) {
    return (
      <Portal
        className="lightbox-backdrop lightbox-backdrop-cc-dialog"
        style={isOnSmallScreen ? { alignItems: 'flex-end' } : {}}
      >
        <FocusTrap focusTrapOptions={{ initialFocus: false, fallbackFocus: 'div' }}>{component}</FocusTrap>
      </Portal>
    )
  } else {
    return null
  }
}

export default translate()(CookieConsentRaw)
