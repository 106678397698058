import React, { useCallback, useMemo, useRef, useState } from 'react'
import { List } from 'immutable'
import { defaults } from 'lodash'
import { useInView } from 'react-intersection-observer'
import cc from 'classcat'

import type { BlockAnimation } from './BlockAnimationSettings'
import { DragSource } from './utils/DragAndDrop'
import { getPlain } from '../../../store/utils'
import BlockAnimationSettings from './BlockAnimationSettings'
import BlockColorSettings from './BlockColorSettings'
import blockTypes from './blocks/index'
import compose from '../../../utils/compose'
import translate from '../../../utils/translate'
import withI18n from '../../withI18n'

export type BackgroundColorSettings = {
  backgroundColor: string
  colorOrGradient: 'color' | 'gradient'
  gradientStartColor: string
  gradientMidColor: string
  gradientEndColor: string
  gradientOrientation: '45deg' | '90deg' | '180deg'
  showGradientMidColor: boolean
}

export const defaultBackgroundColorSettings: BackgroundColorSettings = {
  backgroundColor: 'transparent',
  colorOrGradient: 'color',
  gradientStartColor: '#ffffff',
  gradientMidColor: '#828282',
  gradientEndColor: '#000000',
  gradientOrientation: '90deg',
  showGradientMidColor: false,
}

type Props = {
  block: ImmutableMap
  blockPosition: number
  isDragActive: boolean
  config: any
  editorView: boolean
  onChange: (block: ImmutableMap) => void
  onDelete: () => void
} & TranslateProps
function Block({
  block,
  blockPosition,
  isDragActive,
  config,
  editorView,
  onChange,
  onDelete,
  t,
}: Props): React.ReactElement {
  const [confirmingDelete, setConfirmingDelete] = useState(false)

  const backgroundColorSettings = block.get('backgroundColorSettings')
  const legacyBackgroundColorSetting = block.get('backgroundColor')

  const initialBackgroundColorSettings = useMemo(() => {
    return defaults(
      {},
      getPlain(backgroundColorSettings),
      { backgroundColor: legacyBackgroundColorSetting },
      defaultBackgroundColorSettings,
    )
  }, [backgroundColorSettings, legacyBackgroundColorSetting])

  const [currentBackgroundColorSettings, setCurrentBackgroundColorSettings] =
    useState<BackgroundColorSettings>(initialBackgroundColorSettings)

  const {
    backgroundColor,
    gradientStartColor,
    gradientMidColor,
    gradientEndColor,
    gradientOrientation,
    colorOrGradient,
    showGradientMidColor,
  } = currentBackgroundColorSettings

  const saveBackground = () => {
    onChange(
      block.set('backgroundColorSettings', {
        backgroundColor,
        gradientStartColor,
        gradientMidColor,
        gradientEndColor,
        gradientOrientation,
        colorOrGradient,
        showGradientMidColor,
      }),
    )
  }

  const blockRef = useRef<HTMLElement>()
  const { ref: inViewRef, inView } = useInView({ triggerOnce: true })

  // Use `useCallback` so we don't recreate the function on each render
  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      blockRef.current = node
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node)
    },
    [inViewRef],
  )

  const [animation, setAnimation] = useState<BlockAnimation>(block.get('blockAnimation', 'none'))
  const [isAnimatingInEditor, setIsAnimatingInEditor] = useState(false)
  const handleAnimationChange = (animation: BlockAnimation) => {
    setAnimation(animation)
    handleAnimationReplay()
  }
  const handleAnimationReplay = () => {
    if (blockRef.current) {
      setIsAnimatingInEditor(true)
      blockRef.current.addEventListener('animationend', () => setIsAnimatingInEditor(false), { once: true })
    }
  }
  const saveAnimationChange = () => onChange(block.set('blockAnimation', animation))

  const actionBarRef = useRef<HTMLDivElement>(null)

  const tScoped = (subKey: string, ...args: any) => t(`components.elementContextBarComponent.${subKey}`, ...args)

  // Build a string representation of the block structure. Examples:
  // epages.image
  // image+text-image+text-image+text
  const blockType: string = (block.getIn(['data', 'columns']) || List()).reduce(
    (blockType: string, column: ImmutableMap) =>
      (blockType && blockType + '-') + (column.has('type') ? column.get('type') : column.keySeq().toJS().join('+')),
    '',
  )

  const BlockComponent = blockTypes[block.get('type')]
  if (!BlockComponent) return <div>{'Unknown block type "' + block.get('type') + '"'}</div>

  const handleDataChange = (data: unknown) => onChange(block.set('data', data))

  return (
    <div
      id={`block-${block.get('_id')}`}
      data-testid={`block-${block.get('_id')}`}
      className={cc([
        'dali-block',
        {
          'block-background': backgroundColor !== 'transparent' || colorOrGradient === 'gradient',
          'drag-active': isDragActive,
          // Only animate when explicitly triggered (editor) / when in view (storefront)
          [animation]: animation !== 'none' && (editorView ? isAnimatingInEditor : inView),
          'will-fade': animation !== 'none' && !editorView, // avoid flash of content before animation starts (storefront)
          'has-animation': animation !== 'none' && editorView, // for styling purposes (editor)
        },
      ])}
      data-block-type={blockType}
      style={{
        backgroundColor: colorOrGradient === 'color' ? backgroundColor : undefined,
        backgroundImage:
          colorOrGradient === 'color'
            ? undefined
            : showGradientMidColor
              ? `linear-gradient( ${gradientOrientation}, ${gradientStartColor}, ${gradientMidColor}, ${gradientEndColor})`
              : `linear-gradient( ${gradientOrientation}, ${gradientStartColor}, ${gradientEndColor})`,
        backgroundOrigin: 'border-box',
      }}
      ref={setRefs}
    >
      {editorView && (
        <div ref={actionBarRef} className="dali-block-actionbar">
          {!confirmingDelete && (
            <DragSource
              type="dali-add"
              className="dali-block-actionbar-button dali-block-actionbar-button-move"
              title={tScoped('moveButton.label')}
              onDrag={() => block}
              blockPosition={blockPosition}
              createPreviewNode={() => {
                const { width = 10 } = actionBarRef.current?.getBoundingClientRect() || {}

                const div = document.createElement('div')
                div.className = 'dali-block-actionbar'
                div.style.width = `${width}px`
                div.style.visibility = 'visible'
                div.style.opacity = '1'

                const div2 = document.createElement('div')
                div2.className = 'dali-block-actionbar-button dali-block-actionbar-button-move'
                div.append(div2)

                return div
              }}
              previewNodeOffset={() => {
                const { width = 10, height = 10 } = actionBarRef.current?.getBoundingClientRect() || {}

                return { x: width / 2, y: height / 2 }
              }}
            />
          )}
          {!confirmingDelete && (
            <div
              className="dali-block-actionbar-button dali-block-actionbar-button-delete"
              title={tScoped('deleteButton.label')}
              onClick={() => setConfirmingDelete(true)}
            />
          )}
          {confirmingDelete && <span>{tScoped('deleteButton.warningMessage')}</span>}
          {confirmingDelete && (
            <div className="dali-block-actionbar-button dali-block-actionbar-button-delete-confirm" onClick={onDelete}>
              <span>{tScoped('deleteConfirmButton.label')}</span>
            </div>
          )}
          {confirmingDelete && (
            <div
              className="dali-block-actionbar-button dali-block-actionbar-button-delete-cancel"
              onClick={() => setConfirmingDelete(false)}
            >
              <span>{tScoped('deleteDismissButton.label')}</span>
            </div>
          )}
          {!confirmingDelete && <div className="dali-block-actionbar-button-separator"></div>}
          {!confirmingDelete && (
            <BlockColorSettings
              currentSettings={currentBackgroundColorSettings}
              onSettingsChange={setCurrentBackgroundColorSettings}
              onCancel={() => setCurrentBackgroundColorSettings(initialBackgroundColorSettings)}
              onSave={saveBackground}
              t={t}
            />
          )}
          {!confirmingDelete && (
            <BlockAnimationSettings
              animation={animation}
              initialAnimation={block.get('blockAnimation', 'none')}
              handleAnimationChange={handleAnimationChange}
              handleAnimationReplay={handleAnimationReplay}
              saveAnimationChange={saveAnimationChange}
              setAnimation={setAnimation}
              t={t}
            />
          )}
        </div>
      )}
      <BlockComponent
        config={config}
        editorView={editorView}
        data={block.get('data')}
        onDataChange={handleDataChange}
        settings={block.get('settings')}
      />
    </div>
  )
}

export default compose(withI18n('interface'), translate())(Block)
