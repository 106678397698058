import type { ReactElement } from 'react'

import Content from '../../templateComponents/Workspace'

type Props = {
  page: Core.Page
}

export default function Page({ page }: Props): ReactElement {
  return (
    <div className="template-page">
      <h1>{page.title}</h1>
      <Content />
    </div>
  )
}
