import { useSelector } from 'react-redux'
import React from 'react'

type PercentageProps = {
  className?: string
  oldPrice: number
  newPrice: number
}

export default function Percentage({ className, oldPrice, newPrice }: PercentageProps): React.ReactElement {
  const locale = useSelector<State, string>((state) => state.getIn(['shop', 'locale']).replace('_', '-'))

  const percentageAmount = newPrice / oldPrice - 1
  const percentageDisplay = new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(percentageAmount)

  return <div className={className}>{percentageDisplay}</div>
}
