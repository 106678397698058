import type { ReactElement } from 'react'
import type { RouteComponentProps } from 'react-router'
import { set } from 'lodash'
import { useSelector } from 'react-redux'
import React from 'react'

import Category from './Category'
import Page from './Page'

const shouldUsePageComponent = (state: State) => Boolean(state.getIn(['shop', 'beyond']))

function setRouterProps(props: Props, usePageComponent: boolean) {
  if (usePageComponent) return set(props, 'params.pageSlug', 'home')
  return {
    ...props,
    isHomepage: !props.params.splat,
  }
}

type Props = RouteComponentProps<any, any>
export default function Home(props: Props): ReactElement {
  const usePageComponent = useSelector<State, boolean>(shouldUsePageComponent)
  const Component = usePageComponent ? Page : Category

  return <Component {...setRouterProps(props, usePageComponent)} />
}

Home.storeUpdate = (props: Props, state: State) => {
  const usePageComponent = shouldUsePageComponent(state)
  const Component = usePageComponent ? Page : Category

  return Component.storeUpdate(setRouterProps(props, usePageComponent), state)
}

Home.meta = (props: Props, state: State, t: TranslateProps['t']): View.Meta[] => {
  const usePageComponent = shouldUsePageComponent(state)
  const Component = usePageComponent ? Page : Category

  return Component.meta ? Component.meta(setRouterProps(props, usePageComponent), state, t) : []
}

Home.link = (props: Props, state: State): View.Link[] => {
  const usePageComponent = shouldUsePageComponent(state)
  const Component = usePageComponent ? Page : Category

  return Component.link ? Component.link(setRouterProps(props, usePageComponent), state) : []
}
