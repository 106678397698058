import { omit } from 'lodash'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'

import * as globalDragStatus from './global-drag-status'

if (typeof window === 'object') {
  window.dali = window.dali || {}
  window.dali.globalDragBlockPosition = null
  window.dali.globalDragData = null
  window.dali.globalDragSource = null
  window.dali.globalDragType = null
}

export class DragSource extends React.Component {
  static propTypes = {
    blockPosition: PropTypes.number,
    component: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired,
    onDrag: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func,
    disabled: PropTypes.bool,
    createPreviewNode: PropTypes.func,
    previewNodeOffset: PropTypes.oneOfType([
      PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      }),
      PropTypes.func,
    ]),
  }

  static get defaultProps() {
    return {
      component: 'div',
    }
  }

  constructor() {
    super()
    this.onDragStart = this.onDragStart.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.state = { isDragActive: false }
  }

  componentWillUnmount() {
    if (window.dali.globalDragSource === this) {
      window.dali.globalDragBlockPosition = null
      window.dali.globalDragData = null
      window.dali.globalDragSource = null
      window.dali.globalDragType = null
    }
  }

  onDragStart(event) {
    const data = Immutable.fromJS(this.props.onDrag(this.props))
    // data type must be a known one, like Text, since else IE crashes
    // set some data because firefox will not support drag-events without data
    if (event.dataTransfer) {
      event.dataTransfer.setData('Url', '#')

      if (event.dataTransfer.setDragImage && this.props.createPreviewNode) {
        // element must be visible by the browser (but not by the user)
        const previewNode = this.props.createPreviewNode()
        previewNode.style.position = 'absolute'
        previewNode.style.zIndex = -10
        document.body.appendChild(previewNode)

        if (typeof this.props.previewNodeOffset === 'function') {
          const { x, y } = this.props.previewNodeOffset()
          event.dataTransfer.setDragImage(previewNode, x, y)
        } else if (this.props.previewNodeOffset) {
          const { x, y } = this.props.previewNodeOffset
          event.dataTransfer.setDragImage(previewNode, x, y)
        } else {
          event.dataTransfer.setDragImage(previewNode)
        }

        this.previewNode = previewNode
      }
    }

    window.dali.globalDragBlockPosition = this.props.blockPosition
    window.dali.globalDragData = data
    window.dali.globalDragSource = this
    window.dali.globalDragType = this.props.type
    this.setState({ isDragActive: true })
  }

  onDragEnd() {
    if (this.previewNode) {
      this.previewNode.remove()
      this.previewNode = null
    }

    window.dali.globalDragBlockPosition = null
    window.dali.globalDragData = null
    window.dali.globalDragSource = null
    window.dali.globalDragType = null
    this.setState({ isDragActive: false })
    this.props.onDragEnd && this.props.onDragEnd()
  }

  render() {
    const { component: Component, disabled, blockPosition, ...other } = this.props
    // take out all properties that are not important for the child-component
    const componentProps = omit(other, ['type', 'onDrag', 'previewNodeOffset', 'createPreviewNode', 'isDragActive'])
    // do not add `isDragActive` to the props when it is a `div`
    if (Component !== 'div') {
      componentProps.isDragActive = this.state.isDragActive
    }
    return (
      <Component {...componentProps} draggable={!disabled} onDragStart={this.onDragStart} onDragEnd={this.onDragEnd} />
    )
  }
}

export class DropTarget extends React.Component {
  static propTypes = {
    dropTargetPosition: PropTypes.number.isRequired,
    component: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired,
    onDrop: PropTypes.func.isRequired,
    onDragStart: PropTypes.func,
    onDragOver: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDragEnter: PropTypes.func,
    onDragLeave: PropTypes.func,
  }

  static get defaultProps() {
    return {
      component: 'div',
      onDragStart: () => {},
      onDragOver: () => {},
      onDragEnd: () => {},
      onDragEnter: () => {},
      onDragLeave: () => {},
    }
  }

  constructor() {
    super()
    this.onGlobalDragStatusChange = this.onGlobalDragStatusChange.bind(this)
    this.onDragEnter = this.onDragEnter.bind(this)
    this.onDragOver = this.onDragOver.bind(this)
    this.onDragLeave = this.onDragLeave.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.state = {
      isDragOver: false,
      isDragActive: false,
      isDropRedundant: false,
    }
  }

  componentDidMount() {
    globalDragStatus.listen(this.onGlobalDragStatusChange)
  }

  componentWillUnmount() {
    globalDragStatus.unlisten(this.onGlobalDragStatusChange)
  }

  onGlobalDragStatusChange(isDragActive, event) {
    const active = isDragActive && window.dali.globalDragType === this.props.type
    if (active) {
      this.props.onDragStart(event)
    } else {
      this.props.onDragEnd(event)
    }
    const dropAllowed = window.dali.globalDragType === this.props.type
    this.setState({
      isDragActive: active,
      isDropRedundant: isDragActive && !dropAllowed,
      dragBlockPosition: window.dali.globalDragBlockPosition,
    })
  }

  onDragEnter(event) {
    if (this.supportsDragEvent(event)) {
      this.setState({ isDragOver: true })
      this.props.onDragEnter()
    } else {
      this.setState({ isDragOver: false })
    }
  }

  onDragOver(event) {
    if (this.supportsDragEvent(event)) {
      event.preventDefault()
      this.setState({ isDragOver: true })
      this.props.onDragOver()
    } else {
      this.setState({ isDragOver: false })
    }
  }

  onDragLeave() {
    this.setState({ isDragOver: false })
    this.props.onDragLeave()
  }

  onDrop(event) {
    event.preventDefault()
    const data = Immutable.fromJS(
      window.dali.globalDragData !== undefined && window.dali.globalDragData !== null
        ? window.dali.globalDragData
        : JSON.parse(event.dataTransfer.getData(this.props.type)),
    )
    this.props.onDrop(data, event)
    this.setState({ isDragOver: false })
  }

  supportsDragEvent(event) {
    if (window.dali.globalDragType) {
      return window.dali.globalDragType === this.props.type
    } else {
      const hasType = (dataTransfer, type) => {
        if (typeof dataTransfer.types.contains === 'function') {
          // firefox still uses deprecated DOMStringList
          return dataTransfer.types.contains(type)
        } else {
          return event.dataTransfer.types.indexOf(type) >= 0
        }
      }

      return hasType(event.dataTransfer, this.props.type)
    }
  }

  render() {
    const {
      dropTargetPosition,
      component,
      type: _type,
      onDragOver: _onDragOver,
      onDrop: _onDrop,
      ...other
    } = this.props
    const Component = component

    const { dragBlockPosition } = this.state

    if (
      dragBlockPosition !== null &&
      (dragBlockPosition === dropTargetPosition || dragBlockPosition + 1 === dropTargetPosition)
    )
      return null

    // only propagate the props that a div is allowed (that is, the html attributes)
    return Component === 'div' ? (
      <Component
        {...other}
        onDragEnter={this.onDragEnter}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
      />
    ) : (
      <Component
        {...other}
        onDragEnter={this.onDragEnter}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        isDragOver={this.state.isDragOver}
        isDragActive={this.state.isDragActive}
        isDropRedundant={this.state.isDropRedundant}
      />
    )
  }
}
