import type { ReactElement } from 'react'

import Content from '../../templateComponents/Workspace'

export default function Home({ themeSettings }: HomeThemeComponentProps & { themeSettings: unknown }): ReactElement {
  return (
    <div className="template-home">
      <pre>
        Version 2 Home.rt Theme Settings:
        {JSON.stringify(themeSettings, null, 2)}
      </pre>
      <hr />
      <Content />
    </div>
  )
}
