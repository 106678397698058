import type { FunctionComponent } from 'react'

import AccordionItem from './AccordionItem'

const Accordion: FunctionComponent<AccordionProps> = ({ data, innerTextClassName, titleClassName }) => {
  return (
    <>
      {data.map((item, index) => (
        <AccordionItem key={index} title={item.title} titleClassName={titleClassName}>
          <div className={innerTextClassName} dangerouslySetInnerHTML={{ __html: item.content }} />
        </AccordionItem>
      ))}
    </>
  )
}

export default Accordion

interface AccordionItemData {
  title: string
  content: string
}

interface AccordionProps {
  data: AccordionItemData[]
  titleClassName?: string
  innerTextClassName?: string
}
