import { Component } from 'react'
import { connect } from 'react-redux'
import { func, string } from 'prop-types'
import { list } from 'react-immutable-proptypes'

import { switchLanguage } from '../../store/actions'
import compose from '../../utils/compose'

export class LanguageSwitchRaw extends Component {
  static propTypes = {
    children: func.isRequired,
    shopLocale: string.isRequired,
    availableLocales: list.isRequired,
    dispatch: func,
  }

  switchLanguage = (locale) => {
    this.props.dispatch(switchLanguage(locale))
  }

  render() {
    const { children, shopLocale, availableLocales } = this.props

    const locales = availableLocales
      .toJS()
      .reduce(
        (acc, locale) =>
          locale.isVisible ? acc.concat({ ...locale, isActive: locale.identifier === shopLocale }) : acc,
        [],
      )

    // If an invisible locale is selected, add it to the select options.
    if (locales.every((locale) => !locale.isActive)) {
      const selectedLocale = availableLocales.toJS().find((locale) => locale.identifier === shopLocale)
      locales.push({ ...selectedLocale, isActive: true })
    }

    return locales.length > 1 ? children(locales, this.switchLanguage) : null
  }
}

export default compose(
  connect((state) => ({
    shopLocale: state.getIn(['shop', 'locale']),
    availableLocales: state.getIn(['shop', 'locales']),
  })),
)(LanguageSwitchRaw)
